import React, { Component } from 'react';


export class Layout extends Component {
    constructor() {
        super();
       
        this.state = { menuToggle: "btn btn-primary menu-toggle" };
    }
    
    

    addClassName() {
        if (this.state.menuToggle.includes("toggled")) {
            this.setState({ menuToggle: "btn btn-primary menu-toggle" });
        } else {
            this.setState({ menuToggle: "btn btn-primary menu-toggle toggled" });
        }      
    }

    
  render () {
    
        return (
   
            <div className="page-content-wrapper">
                {/*<nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
                    <button className={this.state.menuToggle} onClick={this.addClassName.bind(this)}>Toggle Menu</button>
    
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
    
                    <div className="collapse navbar-collapse navbarSupportedContent">
                        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                            <li className="nav-item active">
                                <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle navbarDropdown" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Dropdown
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                    <a className="dropdown-item" href="#">Action</a>
                                    <a className="dropdown-item" href="#">Another action</a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="#">Something else here</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>*/}
    
                <div className="container-fluid p-4">
                    {this.props.children}
                </div>
            </div> 
        );
    }
}
