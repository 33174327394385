import React, {useMemo, useCallback, Component} from 'react';
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import arrowsIcon from '../assets/images/arrows.png';
import downloadIcon from '../assets/images/down-arrow.png';
import Loader from '../components/loader';
import { config } from '../Config';
import {CSVLink, CSVDownload} from 'react-csv';
const apiUrl = config.url.APP_API_URL;

class FileUploadHandlesToIds extends Component {

  constructor(props) {

    super(props);

    let uploadFunction = this.props.uploadFunction;

    this.state = {
      validFile: null,
      files: [],
      recordCount: 0,
      twitterAccounts: 0,
      dataLimit: 0,
      timeEstimate: 0,
      showResults: false,
      loadingFile: false,
      errorMessage: null,
      timeEstimateDays: 0,
      timeEstimateHours: 0,
      timeEstimateMinutes: 0,
      file: null,
      twitterAccountDetails: [],
      downloadData: false,
      fileOutputData: []
    };

    // File upload callback 
    this.onDrop = (files) => {
      resetState();
      this.setState({loadingFile: true});
        files.forEach((file) => {

            if(file.type === "text/csv" || file.type === "application/vnd.ms-excel") {  
              
              this.setState({file: file});

              // Read and parse csv file
              Papa.parse(file, {
                  worker: true,
                  header: true,
                  complete: function(results) {
                    // Validate data in file 
                    dataValidator(results, file);
              
                  }
              });
            } else {
              resetState();
              this.setState({loadingFile: false});
              displayEror("Please upload a valid .CSV file.")
            }
        });
    };

    const displayEror = (msg) => {
      this.setState({errorMessage: msg});
    }
    // Validate uploaded file 
    // CSV must contain 
    const dataValidator = async (results, file) => {

      const conversionFileHeaders = [
        "Follower ID",
        "Twitter Handle"
      ]

      if(uploadFunction === 'conversion'){
        const validaConversionFile = await validateConversionFile(conversionFileHeaders, results.meta.fields);
        if(validaConversionFile){
          convertFollowerData(results, file);
        } else {
          resetState();
          this.setState({loadingFile: false});
          displayEror("Error: The headers on this file do not match the template.");
        }
      }
    }

    const convertFollowerData = async(results, file) => {
      let ids = [];
      let handles = [];
      let totalTwitterAccounts = [];
      let accounts = {};
      let accountData = [];
      let accountErrors = [];
      let twitterResponse;
      // Generate array of ids for Twitter API call
      // TODO - break out into own function 
      results.data.forEach((row, index) => {
        if(row['Follower ID']){
          row['Follower ID'] = row['Follower ID'].trim();
          ids.push(row['Follower ID']);
        }
        if(row['Twitter Handle']){
          handles.push(row['Twitter Handle']);
        }
      });

      // TO DO - Move to separate function 
      if(ids.length > 0 || handles.length > 0){
  
        // Get twitter data by IDs
        if(ids.length > 0){
          twitterResponse = await getTwitterFollowerData(ids);
        }
        // Get twitter data by handles 
        else {
          twitterResponse = await getTwitterFollowerDataByHandles(handles);
        }

        if(!twitterResponse.ok){
          //TO DO ERROR
        } else {
          const twitterData = await twitterResponse.json();
        
          //this.state.twitterAccounts = 0;
          for(const followers of twitterData){
            //this.state.twitterAccountDetails.push(followers);
            totalTwitterAccounts.push(followers);
            for(const user of followers.data){     
                this.state.twitterAccounts += user.public_metrics.followers_count;
            }
          }

          for(const t of totalTwitterAccounts){
            accountData.push(t.data);
            if(t.errors){
              accountErrors.push(t.errors);
            }
          }

          let mergedAccounts = [].concat.apply([], accountData);
          let mergedErrors = [].concat.apply([], accountErrors);

          accounts["data"] = mergedAccounts;
          if(accountErrors){
            accounts["errors"] = mergedErrors;
          }

          this.setState({
            showResults: true,
            validFile: true,
            recordCount: results.data.length,
            twitterAccounts: this.state.twitterAccounts,
          });

          let outputData = [];
          mergedAccounts.forEach((row, index) => {
            
            outputData.push({
              "Followerid": "",
              "account id": row['id'],
              "Twitter Handle": row['username'], 
              "Client": "",
              "Project": "",
              "Platform": "",
              "Handle_Type": "",
              "Date_Pulled": "",
              "Inf_Type": "",
              "Inf_Lane": "",
            });
          });

          setTimeout(
            () => {
              this.setState({loadingFile: false, downloadData: true, fileOutputData: outputData});
            }, 
            1000
          );
        }
      }
    }

    // Validate file has Twitter ID or Handle column
    const validateConversionFile = async (headers, fileHeaders) => {
      return headers.some(r=> fileHeaders.indexOf(r) >= 0)
    }

    // Get follower data
    // Twitter API accepts batches up to 99 and returns public_metrics
    const getTwitterFollowerDataByHandles = async (handles) => {
      return fetch(apiUrl + '/api/v1/getTwitterFollowerDataByHandles', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({handles})
      });
    }

    // Get follower data
    // Twitter API accepts batches up to 99 and returns public_metrics
    const getTwitterFollowerData = async (ids) => {
      return fetch(apiUrl + '/api/v1/getTwitterFollowerData', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ids})
      });
    }

    // Reset all state variables 
    const resetState = () => {
      this.setState({
        validFile: null,
        files: [],
        recordCount: 0,
        twitterAccounts: 0,
        showResults: false,
        loadingFile: false,
        errorMessage: null,
        twitterAccountDetails: null
      });
    }
  }

  clearForm = () => {
    this.setState({loadingFile: false, downloadData: false, fileOutputData: []});
  }

  render() {

    const loadingFile = this.state.loadingFile;
    const downloadData = this.state.downloadData;

    return (

      <Dropzone onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => (
          <section className="container px-0">
            <div className="file-upload" {...getRootProps({})}>
              <div className="file-upload-container position-relative d-flex align-items-center justify-content-center px-4">
              {!loadingFile && !downloadData &&
                <div className="file-upload-form d-flex py-4 align-items-center justify-content-center position-relative">
                  <input {...getInputProps()} />
                  <span className="font-weight-bold h5 mb-0 pb-0">Handles</span>
                  <img className="folder-icon mx-3 small" src={arrowsIcon} />
                  <span className="font-weight-bold h5 mb-0 pb-0">IDs</span>
                </div>
                }
                {loadingFile &&
                  <div className="loader-container loader-container-btn d-flex w-100 p-4 text-center justify-content-center align-items-center">
                    <Loader />
                  </div>
                }
                {downloadData && !loadingFile &&
                <div className="py-4">
                  <CSVLink 
                  data={this.state.fileOutputData}
                  filename={this.state.file.name.replace(".csv", "") + "-converted.csv"}
                  onClick={this.clearForm} 
                  className="btn bg-brand-blue btn-download text-white font-weight-bold d-flex align-items-center px-4 ml-auto light-box-shadow-x">Download File
                  <img className="ml-3" src={downloadIcon} />
                  </CSVLink>
                </div>
                }
                </div>
            </div>
          </section>
        )}
      </Dropzone>
    );
  }
}


export default FileUploadHandlesToIds;
