
const prod = {
    url: {
     API_URL: "https://app.haystack.formativeco.com",
     API_KEY: "cddf2237-572d-4706-ab8c-3835aa902151",
     APP_API_URL: "https://haystack.formdev.io",
   }
}

// const dev = {
//     url: {
//         API_URL: "https://app.haystack.formativeco.com",
//         API_KEY: "cddf2237-572d-4706-ab8c-3835aa902151",
//         APP_API_URL: "https://haystack.formdev.io"
//     }
// };

// local dev 
const dev = {
    url: {
        API_URL: "https://app.haystack.formativeco.com",
        API_KEY: "cddf2237-572d-4706-ab8c-3835aa902151",
        APP_API_URL: "http://localhost:3000"
    }
};
   

export const config = process.env.NODE_ENV === "development" ? dev : prod;