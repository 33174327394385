import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import { withRouter } from 'react-router-dom';
import { InputGroup } from 'reactstrap';
import Loader from '../components/loader';
import { config } from '../Config';
const apiUrl = config.url.API_URL;
const apiKey = config.url.API_KEY;

const columns = [
    {
        name: 'Job Code',
        selector: row => row.projectcode,
        sortable: true,
        width: '115px'
    },
    {
        name: 'Job Name',
        selector: row => row.jobcode,
        sortable: true,
        width: '120px'
    },
    {
      name: 'Job Type',
      selector: row => row.jobtype,
      sortable: true,
      width: '150px'
    },
    {
      name: 'Cycle',
      selector: row => row.cycle,
      sortable: true,
      width: '90px'
    },
    {
      name: 'Submitted',
      selector: row => row.uploaddate,
      sortable: true,
      // cell:(row) => row.startdate.toLocaleString(),
      width: '150px'
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      conditionalCellStyles: [
        {
          when: row => row.status === "Running",
          style: {
            color: '#28a745'
          },
        },
        {
          when: row => row.status ===  "Done",
          style: {
            color: '#00A7D0'
          },
        },
        {
          when: row => row.status === "Pending",
          style: {
            color: '#ffc107'
          },
        },
      ],
    },
    {
      name: '~ Run Time',
      selector: row => row.runtime,
      sortable: true,
      width: '125px'
    },
    {
      name: 'Followers',
      selector: row => row.followers,
      sortable: true,
      width: '115px'
    },
    {
      name: 'Started',
      selector: row => row.startdate,
      sortable: true,
      width: '150px'
    },
    {
      name: 'Completed',
      selector: row => row.enddate,
      sortable: true,
      width: '150px'
    },
    {
      name: 'Run Time',
      selector: row => row.actualruntime,
      sortable: true,
      width: '120px'
    },
    {
      name: 'Owner',
      selector: row => row.submittedby,
      sortable: true,
      width: '150px'
    }
];

const data = [
    {
        id: 1,
        jobID: '0023',
        projectCode: '07-014',
        jobType: 'Twitter IDs',
        date: '7/4/21 at 7:30PM',
        submitter: 'Gernot Kalcher',
        status: 'Running',
        followers: '56,234',
        estRunTime: '15.6 hrs'
    },
    {
      id: 2,
      jobID: '0024',
      projectCode: '09-014',
      jobType: 'Twitter IDs',
      date: '7/5/21 at 5:30PM',
      submitter: 'Eric Kaplan',
      status: 'Queued',
      followers: '156,234',
      estRunTime:(row) => <a to="/job/{row.jobID}" id={row.ID}>Action</a>,
    },
]

  const  handleButtonClick = (state) => {
    console.log('clicked');
    console.log(state.target.id);
  };
class JobTable extends Component {

    constructor(props) {
        super(props);
        this.state = { 
          jobs: [], 
          loading: true,
          pendingJobs: [],
          completedJobs: [],
          runningJobs: []
         };

         this.handleChange = this.handleChange.bind(this);
         this.viewJob = this.viewJob.bind(this);
    }

    componentDidMount() {
      this.getJobs();
  }

    getJobs() {
      fetch(apiUrl + '/tasks/all?key=' + apiKey)
          .then(res => res.json())
          .then((data) => {

              data.tasks.sort(function(a,b){
                return new Date(b.startdate) - new Date(a.startdate);
              });

              console.log('Jobs: ', data.tasks);

              data = this.formatData(data.tasks);

              const pendingJobs = data.filter(obj => {
                return obj.status === 'Pending';
              });
              const completedJobs = data.filter(obj => {
                return obj.status === 'Done';
              });
              const runningJobs = data.filter(obj => {
                return obj.status === 'Running';
              });
              this.setState({ 
                jobs: data, 
                loading: false,
                pendingJobs: pendingJobs,
                completedJobs: completedJobs,
                runningJobs: runningJobs
              });
          })
          .catch((error) => {
            this.setState({ 
              jobs: '',
              loading: false,
              pendingJobs: '',
              completedJobs: '',
              runningJobs: ''
            });
          });
    }

    formatData(items) {

      items.forEach((item) => {

          // // Event Date Day ( 2 Digit )
          if (item["startdate"]) {
              item["startdate"] = new Intl.DateTimeFormat('en-US', {month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(item["startdate"]));
          } else {
            item["startdate"] = "N/A";
          }

          // // Event Date Day ( 2 Digit )
          if (item["uploaddate"]) {
            item["uploaddate"] = new Intl.DateTimeFormat('en-US', {month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(item["uploaddate"]));
          }

          if (item["enddate"] != null) {
            item["enddate"] = new Intl.DateTimeFormat('en-US', {month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(item["enddate"]));
          } else {
            item["enddate"] = "N/A";
          }

          // Calculate and format est run time 
          if(item["followers"]){
            item["followers"] = parseInt(item['followers']).toLocaleString();
          }

          // Calculate and format est run time 
          if(item["runtime"]){
            item["runtime"] = this.calculateJobTimeEstimate(item);
          }

          // Calculate and format est run time 
          if(item["enddate"] != null){
          let actualRunTime = this.calculateJobTime(item);
            item["actualruntime"] = actualRunTime;
          } else {
            item["actualruntime"] = "N/A";
          }
      });

      return items;
    }

    calculateJobTimeEstimate(job){

      let minutes = job["runtime"];
      let minutesLeft = 0;
      let hoursLeft = 0;
      let daysLeft = 0;
      let estRunTime = "N/A";

      if(minutes > 1440){
        let time = minutes / 60;
        let days = Math.floor(time/24);
        let remainder = time % 24;
        let hours = Math.floor(remainder);
        let hoursRemainder = remainder % 60;
        let tempMinutes = Math.floor(hoursRemainder);
        daysLeft = days;
        hoursLeft = hours;
        minutesLeft = tempMinutes;
        estRunTime = daysLeft + "d " + hoursLeft + "h " + minutesLeft + "m";
      } 
      if (minutes > 60 && minutes <= 1440) {
        let hours = Math.floor(minutes / 60);
        let remainder = minutes % 60;
        let tempMinutes = Math.floor(remainder);
        daysLeft = 0;
        hoursLeft = hours;
        minutesLeft = tempMinutes;
        estRunTime = hoursLeft + "h " + minutesLeft + "m";
      }
      if (minutes < 60)  {
        daysLeft = 0;
        hoursLeft = 0;
        minutesLeft = minutes;
        estRunTime = minutesLeft + "m";
      }

      return estRunTime;

    }

    calculateJobTime(job){

      let startDate = new Date(job["startdate"]);
      let endDate   = new Date(job["enddate"]);
      let minutes = ((endDate.getTime() - startDate.getTime()) / 1000) / 60;
      let runTime = "N/A";

      if(minutes > 1440){
        runTime = Math.floor(minutes/24/60) + "d " + Math.floor(minutes/60%24) + "h " + Math.floor(minutes%60) + "m";
      } 
      if (minutes > 60 && minutes <= 1440) {
        runTime = Math.floor(minutes/60%24) + "h " + Math.floor(minutes%60) + "m";
      }
      if (minutes < 60)  {
        runTime = Math.floor(minutes%60) + "m";
      }

      return runTime;
    }

    handleChange = (state) => { 
        this.props.history.push(`/jobs/` + state.jobID);
    }

    viewJob = (job) => {
      this.props.history.push(`/job/` + job.id);
    }

    static displayName = JobTable.name;

    render() {

      let contents = this.state.loading
          ? 
          <div className="loader-container d-flex flex-column h-100 p-4 text-center justify-content-center align-items-center">
            <Loader />
          </div>
          :             <div className="row">
          {this.state.runningJobs.length > 0 &&
            <div className="col-12 pb-4 mb-4">
                <h5 className="text-uppercase font-weight-bold fade-up fade-4">Running ({this.state.runningJobs.length})</h5>
                <div className="light-box-shadow-x mt-4 fade-up fade-5">
                <DataTable
                    columns={columns}
                    data={this.state.runningJobs}
                    onRowClicked={this.viewJob}
                    pagination
                />
                </div>
            </div>
            }  
          {this.state.pendingJobs.length > 0 &&
            <div className="col-12 mb-4 pb-4">
                <h5 className="text-uppercase font-weight-bold fade-up fade-5">Pending ({this.state.pendingJobs.length})</h5>
                <div className="light-box-shadow-x mt-4 fade-up fade-6">
                <DataTable
                    columns={columns}
                    data={this.state.pendingJobs}
                    onRowClicked={this.viewJob}
                    pagination
                />
                </div>
            </div>
          }
          {this.state.completedJobs.length > 0 &&
            <div className="col-12">
                <h5 className="text-uppercase font-weight-bold fade-up fade-7">Completed ({this.state.completedJobs.length})</h5>
                <div className="light-box-shadow-x mt-4 fade-up fade-8">
                <DataTable
                    columns={columns}
                    data={this.state.completedJobs}
                    onRowClicked={this.viewJob}
                    pagination
                />
                </div>
            </div>
            }    
              {!this.state.pendingJobs.length > 0 && !this.state.completedJobs.length > 0 && !this.state.runningJobs.length > 0 &&
              <div className="col-12">
                <h5 className="fade-up fade-4 text-center font-italic py-4">There are no jobs to show.</h5>
              </div>
              }
        </div>
  
      return (
          <div className="jobs-container">
              {contents}
          </div>
      );
    }    
}



export default withRouter(JobTable);
