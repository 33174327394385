import React, { Component } from 'react';
import { NavbarBrand, NavLink, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import './nav.css';
import logo from '../../assets/images/formative-haystack.svg';

export class Nav extends Component {
    static displayName = Nav.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
        <div className="sidebar-wrapper light-box-shadow-x border-right d-flex col">
          <div className="container-fluid d-flex">
              <div className="sidebar-heading d-flex align-items-center">
                  <NavbarBrand tag={Link} to="/" className="m-0 ml-auto nav-logo navbar-brand d-flex align-items-center">
                    <img src={logo} />
                    <span className="divider"></span>
                    <span className="font-weight-bold text-uppercase text-dark">Haystack</span>
                    </NavbarBrand>
              </div>
              <div className="d-flex ml-auto">
                  <NavLink tag={Link} className="d-flex align-items-center px-4 nav-link" to="/estimate">Estimate</NavLink>
                  <NavItem className="nav-item dropdown nav-item list-unstyled align-items-center d-flex">
                    <NavLink tag={Link} className="d-flex align-items-center px-4 nav-link dropdown-toggle" to="#" id="navbarToolsDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tools</NavLink>
                    <div className="dropdown-menu light-box-shadow-x" aria-labelledby="navbarToolsDropdown">
                      <NavLink tag={Link} className="d-flex align-items-center px-4 nav-link" to="/tools/conversions">Conversions</NavLink>
                      <NavLink tag={Link} className="d-flex align-items-center px-4 nav-link" to="/tools/follower-data">Follower Data</NavLink>
                      <a rel="noopener noreferrer" className="d-flex align-items-center px-4 nav-link" href="https://s3.console.aws.amazon.com/s3/buckets/lists.haystackaudiences.com" target="_blank">Access S3 buckets in AWS</a>
                      
                    </div>
                  </NavItem>
                  <NavLink tag={Link} className="d-flex align-items-center px-4 nav-link" to="/submit">Submit A Job</NavLink>
                  <NavLink tag={Link} className="d-flex align-items-center px-4 nav-link" to="/queue">Job Queue</NavLink>
              </div>
            </div>
        </div>
    );
  }
}
