import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import { withRouter } from 'react-router-dom';
import Loader from '../components/loader';
import { config } from '../Config';
const apiUrl = config.url.API_URL;
const apiKey = config.url.API_KEY;

const columns = [
    {
        name: 'Job Code',
        selector: row => row.projectcode,
        sortable: true
    },
    {
        name: 'Job Name',
        selector: row => row.jobcode,
        sortable: true
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true
    },
];

class JobQueuePreview extends Component {

    constructor(props) {
        super(props);
        this.state = { 
          jobs: [], 
          loading: true,
          pendingJobs: [],
          completedJobs: [],
          runningJobs: []
         };

         let jobData = this.props.jobData;

         this.handleChange = this.handleChange.bind(this);
         this.viewJob = this.viewJob.bind(this);
    }

    componentDidMount() {
      this.getJobs();
  }

  jobData = () => (
    this.getJobs()
  )

    getJobs() {
      fetch(apiUrl + '/tasks/all?key=' + apiKey)
          .then(res => res.json())
          .then((data) => {

              data.tasks.sort(function(a,b){
                return new Date(b.startdate) - new Date(a.startdate);
              });

              const pendingJobs = data.tasks.filter(obj => {
                return obj.status === 'Pending';
              });
              const completedJobs = data.tasks.filter(obj => {
                return obj.status === 'Done';
              });
              const runningJobs = data.tasks.filter(obj => {
                return obj.status === 'Running';
              });
              this.setState({ 
                jobs: data.tasks, 
                loading: false,
                pendingJobs: pendingJobs,
                completedJobs: completedJobs,
                runningJobs: runningJobs
              });
          })
          .catch((error) => {
            this.setState({ 
              jobs: '',
              loading: false,
              pendingJobs: '',
              completedJobs: '',
              runningJobs: ''
            });
          });
  }


    handleChange = (state) => { 
        this.props.history.push(`/jobs/` + state.jobID);
    }

    viewJob = (job) => {
      this.props.history.push(`/job/` + job.id);
    }

    static displayName = JobQueuePreview.name;

    render() {


      let contents = this.state.loading
          ? 
          <div className="loader-container d-flex flex-column h-100 p-4 text-center justify-content-center align-items-center">
            <Loader />
          </div>
          :             <div className="row">
          {this.state.runningJobs.length > 0 &&
            <div className="col-12 mb-4">
                <h5 className="text-uppercase font-weight-bold fade-up fade-4">Running ({this.state.runningJobs.length})</h5>
                <div className="light-box-shadow-x mt-4 fade-up fade-5">
                <DataTable
                    columns={columns}
                    data={this.state.runningJobs}
                    onRowClicked={this.viewJob}
                />
                </div>
            </div>
            }  
          {this.state.pendingJobs.length > 0 &&
            <div className="col-12 mb-4 pb-4">
                <h5 className="text-uppercase font-weight-bold fade-up fade-4">Pending ({this.state.pendingJobs.length})</h5>
                <div className="light-box-shadow-x mt-4 fade-up fade-5">
                <DataTable
                    columns={columns}
                    data={this.state.pendingJobs}
                    onRowClicked={this.viewJob}
                />
                </div>
            </div>
          }
              {!this.state.pendingJobs.length > 0 && !this.state.runningJobs.length > 0 &&
              <div className="col-12">
                <h5 className="fade-up fade-4 text-center font-italic py-4">There are no jobs to show.</h5>
              </div>
              }
        </div>
  
      return (
          <div className="jobs-container">
              {contents}
          </div>
      );
    }    
}



export default withRouter(JobQueuePreview);
