import React, { Component } from 'react';
import FileUpload from '../components/file-upload';
import JobTable from '../components/job-table';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Job } from './job';
import Loader from '../components/loader';
import { config } from '../Config';
import FileUploadHandlesToIds from '../components/file-upload-handles-to-ids';
const apiUrl = config.url.API_URL;
const apiKey = config.url.API_KEY;

export class Dashboard extends Component {
    static displayName = Dashboard.name;

    constructor(props) {
      super(props);
      this.state = {
        file: null,
        followers: '',
        runTime: '',
        fileRecords: null,
        rowClick: '',
        loading: true,
        totalJobs: 0,
        totalAccounts: 0,
        totalFollowers: 0
      };

      this.handleRowClick = this.handleRowClick.bind(this);
    }

    componentDidMount() {
      this.getJobs();
    }


    pushFile = (file, followers, runTime, records, followerCount) => {
      this.setState({
          file: file,
          followers: followers,
          runTime: runTime,
          records: records,
          followerCount: followerCount
      });
    }

    handleRowClick = (event) => {
      event.preventDefault();
     // this.props.history.push(`/jobs/` + state.jobID);
    }

    getJobs() {
      fetch(apiUrl + '/tasks/all?key=' + apiKey)
          .then(res => res.json())
          .then((data) => {
              let totalAccounts = 0; 
              let totalFollowers = 0;
              let totalJobs = 0;
              data.tasks.forEach(function (task, index) {
                if(task.status != "Canceled"){
                  totalJobs += 1;
                  totalAccounts += parseInt(task.total_accounts);
                  totalFollowers += parseInt(task.followers);
                }
              });
              this.setState({ 
                jobs: data.tasks, 
                loading: false,
                totalJobs: totalJobs,
                totalAccounts: totalAccounts,
                totalFollowers: totalFollowers
              });
          })
          .catch((error) => {
            this.setState({ 
              loading: false
            });
          });
  }

  render() {


    let contents = this.state.loading
        ? 
        <div className="loader-container d-flex flex-column h-100 p-4 text-center justify-content-center align-items-center">
          <Loader />
        </div>
        :
        <div className="container-fluid mt-4">
        <div className="row pb-4">
          <div className="col-12 pb-2 mb-4 d-flex align-items-center fade-up">
            <h2 className="font-weight-bold mb-0 text-uppercase">Dashboard</h2>
            <NavLink tag={Link} className="btn bg-brand-blue text-white font-weight-bold d-flex align-items-center px-4 ml-auto light-box-shadow-x" to="/submit">Submit A Job +</NavLink>
          </div>
          <div className="col-12 col-md-4 fade-up fade-2">
            <div className="card bg-white px-4 py-3 light-box-shadow-x">
              <h4 className="font-weight-bold pt-2">Total Jobs</h4>
              <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.totalJobs.toLocaleString()}</h5>
            </div>
          </div>
          <div className="col-12 col-md-4 fade-up fade-3">
          <div className="card bg-white px-4 py-3 light-box-shadow-x">
              <h4 className="font-weight-bold pt-2">Total Accounts</h4>
              <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.totalAccounts.toLocaleString()}</h5>
            </div>
          </div>
          <div className="col-12 col-md-4 fade-up fade-4">
          <div className="card bg-white px-4 py-3 light-box-shadow-x">
              <h4 className="font-weight-bold pt-2">Total Followers</h4>
              <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.totalFollowers.toLocaleString()}</h5>
            </div>
          </div>
        </div>
        <div className="row my-4">
        {/* <div class="col-12">
          <h3 className="text-uppercase font-weight-bold fade-up fade-8">Tools</h3>
          </div>
          <div className="col-12 col-lg-8 d-flex flex-wrap">
            <div className="col-12 col-md-6 pl-0">
              <div className="fade-up fade-9 bg-white p-4 light-box-shadow-x mt-4">
                <FileUploadHandlesToIds uploadFunction = {'estimate'} />
              </div>
            </div>
          </div> */}
          <div className="col-12 col-lg-8">
            <div className="d-flex align-items-end">
              <h3 className="font-weight-bold mb-0 text-uppercase fade-up fade-5">Recent Jobs</h3>
              <NavLink tag={Link} to="/queue" className="font-weight-bold ml-auto fade-up fade-6">View All Jobs</NavLink>
            </div>
            <div className="mt-4 fade-up fade-7">
              <JobTable viewJob = {this.viewJob}/>
            </div>
          </div>
          <div className="col-12 col-lg-4 mt-4 pt-4 pt-lg-0 mt-lg-0">
            <h4 className="text-uppercase font-weight-bold fade-up fade-8">Tools</h4>
            <div className="fade-up fade-9 bg-white p-4 light-box-shadow-x mt-4">
              <FileUploadHandlesToIds uploadFunction = {'conversion'} />
            </div>
            <h4 className="text-uppercase font-weight-bold fade-up fade-10 mt-4 pt-0 pt-md-4">Job Estimate</h4>
            <p className="fade-up fade-11">Upload a .csv file to find the total number of followers and get an estimated completion time for the data pull via Haystack.</p>
            <div className="fade-up fade-12 bg-white p-4 light-box-shadow-x">
              <FileUpload pushFile = {this.pushFile} uploadFunction = {'estimate'} />
            </div>
          </div>
        </div>
      </div>


    return (
        <div className="jobs-container">
            {contents}
        </div>
    );
  }    


}
