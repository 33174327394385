import React, { Component } from 'react';
import JobTable from '../components/job-table';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Loader from '../components/loader';
import { config } from '../Config';
const apiUrl = config.url.API_URL;
const apiKey = config.url.API_KEY;

export class Queue extends Component {
    static displayName = Queue.name;

    constructor(props) {
      super(props);
      this.state = {
        daysLeft: 0,
        hoursLeft: 0,
        minutesLeft: 0,
        endTime: null,
        loading: true,
        jobs: [], 
        loading: true,
        pendingJobs: [],
        completedJobs: [],
        runningJobs: [],
        queueDaysLeft: 0,
        queueHoursLeft: 0,
        queueMinutesLeft: 0,
      }
    }

    getJobs() {
      fetch(apiUrl + '/tasks/all?key=' + apiKey)
          .then(res => res.json())
          .then((data) => {

              data.tasks.sort(function(a,b){
                return new Date(b.startdate) - new Date(a.startdate);
              });

              const pendingJobs = data.tasks.filter(obj => {
                return obj.status === 'Pending';
              });
              const completedJobs = data.tasks.filter(obj => {
                return obj.status === 'Done';
              });
              const runningJobs = data.tasks.filter(obj => {
                return obj.status === 'Running';
              });
              if(pendingJobs.length > 0){
                let estTime = 0;
                pendingJobs.forEach((job, index) => {
                  if(job['runtime']){
                    estTime += parseInt(job['runtime']);
                  }
                });
                this.calculatePendingJobTime(estTime);
              }
              if(runningJobs.length > 0){
                this.calculateJobTime(runningJobs[0]);
              }
              this.setState({ 
                jobs: data.tasks, 
                loading: false,
                pendingJobs: pendingJobs,
                completedJobs: completedJobs,
                runningJobs: runningJobs
              });
          })
          .catch((error) => {
            this.setState({ 
              jobs: '',
              loading: false,
              pendingJobs: '',
              completedJobs: '',
              runningJobs: ''
            });
          });
  }

  calculatePendingJobTime(minutes){
    this.setState({
      queueDaysLeft: Math.floor(minutes/24/60),
      queueHoursLeft: Math.floor(minutes/60%24),
      queueMinutesLeft: Math.floor(minutes%60)
    });
  }

  calculateJobTime(job){

    let minutesLeft = 0;
    let hoursLeft = 0;
    let daysLeft = 0;

    let diff = Math.abs(new Date() - new Date(job.startdate));
    let tempMinutes = Math.floor((diff/1000)/60);

    const minutes = job.runtime - tempMinutes;

    let currentDate = new Date();
    console.log('Current Date: ' + currentDate);
    if(daysLeft > 0){
      currentDate.addDays(daysLeft);
    }
    if(hoursLeft > 0){
      currentDate.setTime(currentDate.getTime() + (hoursLeft*60*60*1000));
    }
    if(minutesLeft > 0){
      currentDate.setTime(currentDate.getTime() + (minutesLeft*60000));
    }

    currentDate = new Intl.DateTimeFormat('en-US', {month: '2-digit', day: 'numeric', hour: '2-digit', minute: '2-digit' }).format(new Date(currentDate));

    this.setState({
      daysLeft: Math.floor(minutes/24/60),
      hoursLeft: Math.floor(minutes/60%24),
      minutesLeft: Math.floor(minutes%60),
      endTime: currentDate
    });
  }

  componentDidMount() {
    this.getJobs();
}

  render () {
    let contents = this.state.loading
    ? 
    <div className="loader-container d-flex flex-column h-100 p-4 text-center justify-content-center align-items-center">
      <Loader />
    </div>
    :
      <div className="container-fluid mt-4">
        <div className="row pb-4 mb-4">
          <div className="col-12 pb-2 mb-4 d-flex align-items-center fade-up">
            <h2 className="font-weight-bold mb-0 text-uppercase">Jobs</h2>
          </div>
          <div className="col-12 col-md-4 fade-up fade-2">
            <div className="card bg-white px-4 py-3 light-box-shadow-x">
              <h4 className="font-weight-bold pt-2">Current Job Estimate</h4>
              {this.state.runningJobs.length > 0 &&
              <h5 className="text-brand-blue font-weight-bold pb-2">
                {this.state.daysLeft > 0 &&
                  <span>
                    {this.state.daysLeft} days&nbsp;
                  </span>
                }
                {this.state.hoursLeft > 0 &&
                  <span>
                    {this.state.hoursLeft} hours
                  </span>
                }
                {this.state.minutesLeft > 0 &&
                  <span>
                    &nbsp;{this.state.minutesLeft} minutes
                  </span>
                }
                {this.state.minutesLeft === 0 && this.state.hoursLeft === 0 && this.state.daysLeft === 0 &&
                <span className="text-muted font-italic">
                  N/A
                </span>
                }
              </h5>
              }
              {this.state.runningJobs.length === 0 &&
                <h5 className="font-weight-bold pb-2"><span className="text-muted font-italic">
                  N/A
                </span></h5>
              }
            </div>
          </div>
          <div className="col-12 col-md-4 fade-up fade-3">
          <div className="card bg-white px-4 py-3 light-box-shadow-x">
              <h4 className="font-weight-bold pt-2">Job Queue</h4>
              <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.pendingJobs.length}</h5>
            </div>
          </div>
          <div className="col-12 col-md-4 fade-up fade-4">
          <div className="card bg-white px-4 py-3 light-box-shadow-x">
              <h4 className="font-weight-bold pt-2">Job Queue Estimate</h4>
              <h5 className="text-brand-blue font-weight-bold pb-2">
              {this.state.queueDaysLeft > 0 &&
                  <span>
                    {this.state.queueDaysLeft} days&nbsp;
                  </span>
                }
                {this.state.queueHoursLeft > 0 &&
                  <span>
                    {this.state.queueHoursLeft} hours
                  </span>
                }
                {this.state.queueMinutesLeft > 0 &&
                  <span>
                    &nbsp;{this.state.queueMinutesLeft} minutes
                  </span>
                }
                {this.state.queueMinutesLeft === 0 && this.state.queueHoursLeft === 0 && this.state.queueDaysLeft === 0 &&
                <span className="text-muted font-italic">
                  N/A
                </span>
                }
              </h5>
            </div>
          </div>
        </div>
        <JobTable />
      </div>
    

    return (
      <div className="jobs-container">
          {contents}
      </div>
  );
  }
}
