import React, {useMemo, useCallback, Component} from 'react';
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import CountUp from 'react-countup';
import ReactTooltip from 'react-tooltip';
import infoIcon from '../assets/images/info.png';
import folderIcon from '../assets/images/folder.png';
import Loader from '../components/loader';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { config } from '../Config';
const apiUrl = config.url.APP_API_URL;

class FileUpload extends Component {

  constructor(props) {

    super(props);

    let pushFile = this.props.pushFile;
    let getFileFollowerData = this.props.getFileFollowerData;
    let uploadFunction = this.props.uploadFunction;

    this.state = {
      validFile: null,
      files: [],
      recordCount: 0,
      twitterAccounts: 0,
      dataLimit: 0,
      timeEstimate: 0,
      showResults: false,
      loadingFile: false,
      errorMessage: null,
      timeEstimateDays: 0,
      timeEstimateHours: 0,
      timeEstimateMinutes: 0,
      file: null,
      twitterAccountDetails: []
    };

    // File upload callback 
    this.onDrop = (files) => {
      resetState();
      this.setState({loadingFile: true});
        files.forEach((file) => {

            if(file.type === "text/csv" || file.type === "application/vnd.ms-excel") {  
              
              this.setState({file: file});

              // Read and parse csv file
              Papa.parse(file, {
                  worker: true,
                  header: true,
                  complete: function(results) {
                    // Validate data in file 
                    if(results.data != 0){
                      dataValidator(results, file);
                    } else {
                      resetState();
                      displayEror("Please upload a file with records. This file contains 0 records.")
                    }
              
                  }
              });
            } else {
              resetState();
              this.setState({loadingFile: false});
              displayEror("Please upload a valid .CSV file.")
            }
        });
    };

    const displayEror = (msg) => {
      this.setState({errorMessage: msg});
    }
    // Validate uploaded file 
    // CSV must contain 
    const dataValidator = async (results, file) => {

      const headers = [
        "Followerid",
        "account id",
        "Twitter Handle",
        "Client",
        "Project",
        "Platform",
        "Handle_Type",
        "Date_Pulled",
        "Inf_Type",
        "Inf_Lane",
      ]

      const followerDataFileHeaders = [
        "Overlap",
        "follower_id"
      ]

      const conversionFileHeaders = [
        "Follower ID",
        "Twitter Handle"
      ]

      if(uploadFunction === 'estimate'){
        const validHeaders = await validateFileHeaders(headers, results.meta.fields);
        if(validHeaders){
          const validData = await validateFileData(results);
          if(validData){
            getFollowerIdData(results, file);
          }
        } else {
          resetState();
          this.setState({loadingFile: false});
          displayEror("Error: The headers on this file do not match the template. Please download the template file from the link above and try again.");
        }
      }
      else if(uploadFunction === 'follower-ids'){
        const validHeaders = await validateFileHeaders(headers, results.meta.fields);
        if(validHeaders){
          const validData = await validateFileData(results);
          if(validData){
            getFollowerIdData(results, file);
          }
        } else {
          resetState();
          this.setState({loadingFile: false});
          displayEror("Error: The headers on this file do not match the template. Please download the template file from the link above and try again.");
        }
      }
      else if(uploadFunction === 'follower-data'){
        const validFollowerDataFile = await validateFollowerDataFile(followerDataFileHeaders, results.meta.fields); 
        if(validFollowerDataFile){
          const validData = await validateFileData(results);
          if(validData){
            getFollowerData(results, file);
          }
        } else {
          resetState();
          this.setState({loadingFile: false});
          displayEror("Error: The headers on this file do not match the template. Please download the template file from the link above and try again.");
        }
      }
      else if(uploadFunction === 'conversion'){
        const validaConversionFile = await validateConversionFile(conversionFileHeaders, results.meta.fields);
        if(validaConversionFile){
          const validData = await validateFileData(results);
          if(validData){
            convertFollowerData(results, file);
          }
        } else {
          resetState();
          this.setState({loadingFile: false});
          displayEror("Error: The headers on this file do not match the template. Please download the template file from the link above and try again.");
        }
      }
    }

    const getFollowerIdData = async(results, file) => {

      let ids = [];
      let handles = [];
      let totalTwitterAccounts = [];

      results.data.forEach((row, index) => {
        if(row['account id']){
          row['account id'] = row['account id'].replaceAll('"','');
          ids.push(parseInt(row['account id']));
        }
        if(row['Twitter Handle']){
          row['Twitter Handle'] = row['Twitter Handle'].replaceAll('"','');
          handles.push(row['Twitter Handle']);
        }
        if(!row['account id'] && !row['Twitter Handle']){
          results.data.splice(index, 1);
        }
        if(row['account id']&& row['Twitter Handle']){
          if(row['account id'] == "" && row['Twitter Handle'] == ""){
            results.data.splice(index, 1);
          }
        }
    });

    console.log('Handles: ', handles);

    // TO DO - Move to separate function 
    if(ids.length > 0){


      // Get haystack settings
      const haystackResponse = await getHaystackSettings();
      if(!haystackResponse.ok){
        this.setState({
          loadingFile: false,
          errorMessage: "Error: " + haystackResponse.statusText
        });
      } else {
        const haystackData = await haystackResponse.json();
        this.state.dataLimit = haystackData.items[0].fields.followerDataLimitPerHour;
      }

      // Get twitter data 
      const twitterResponse = await getTwitterFollowers(handles);
      if(!twitterResponse){
        if(!twitterResponse === undefined){
          this.setState({
            loadingFile: false,
            errorMessage: "Error: " + twitterResponse.statusText
          });
        } 
      }

      else {
        const twitterData = await twitterResponse.json();
        
        
        //this.state.twitterAccounts = 0;
        for(const followers of twitterData){
          //this.state.twitterAccountDetails.push(followers);
          totalTwitterAccounts.push(followers);
          for(const user of followers.data){     
              this.state.twitterAccounts += user.public_metrics.followers_count;
          }
        }

        let accounts = {};
        let accountData = [];
        let accountErrors = [];

        for(const t of totalTwitterAccounts){
          accountData.push(t.data);
          if(t.errors){
            accountErrors.push(t.errors);
          }
        }

        let mergedAccounts = [].concat.apply([], accountData);
        let mergedErrors = [].concat.apply([], accountErrors);

        accounts["data"] = mergedAccounts;
        if(accountErrors){
          accounts["errors"] = mergedErrors;
        }
       

        //const flattened = totalTwitterAccounts.flat(Infinity);
        this.setState({
          twitterAccountDetails: accounts
      });
      }

      // Calculate job run time 
      // Total follower count divided by haystack hourly limit divided by 24 (hours)
      let time = 0;
      if(this.state.twitterAccounts < this.state.dataLimit){
        time = Math.ceil((this.state.twitterAccounts / this.state.dataLimit) * 60);
        this.setState({timeEstimateMinutes: time});
      } else {
        time = this.state.twitterAccounts / this.state.dataLimit;
        if(time > 72){
          formatTimeEstimate(time);
        } else {
          this.setState({timeEstimateHours: Math.round(time)});
        }
      }
      let timeEstimate = Math.ceil((this.state.twitterAccounts / this.state.dataLimit) * 60);

      // Display results 
      this.setState({
        showResults: true,
        validFile: true,
        recordCount: results.data.length,
        dataLimit: this.state.dataLimit,
        twitterAccounts: this.state.twitterAccounts,
        timeEstimate: timeEstimate
      });

      setTimeout(
        () => {
          this.setState({loadingFile: false});
          pushFile(file,this.state.twitterAccountDetails, timeEstimate, this.state.recordCount, this.state.twitterAccounts);
        }, 
        1000
      );
    } 

    else {
      resetState();
      this.setState({loadingFile: false});
      displayEror("Please upload a file with at least one (1) record.");
    }
    }

    const getFollowerData = async(results, file) => {
      let ids = [];
      let handles = [];
      let totalTwitterAccounts = [];
      let accounts = {};
      let accountData = [];
      let accountErrors = [];
      // Generate array of ids for Twitter API call
      // TODO - break out into own function 
      results.data.forEach((row, index) => {
        if(row['Follower ID']){
          row['Follower ID'] = row['Follower ID'].trim();
          ids.push(row['Follower ID']);
        }
        if(row['follower_id']){

          let tempId = row['follower_id'].trim();
          let isNum = /^\d+$/.test(tempId);

          if(isNum){
            ids.push(tempId);
          }
          
        }
        if(row['created_at']){
          row['created_at'] = new Intl.DateTimeFormat('en-US', {month: '2-digit', day: 'numeric', year: 'numeric' }).format(new Date(row['created_at']));
        }
        // Remove empty rows
        // if(!row['account id'] && !row['Twitter Handle']){
        //   results.data.splice(index, 1);
        // }
      });

      // TO DO - Move to separate function 
      if(ids.length > 0){
  
        // Get twitter data 
        const twitterResponse = await getTwitterFollowerData(ids);
        if(!twitterResponse){
          if(!twitterResponse === undefined){
            this.setState({
              loadingFile: false,
              errorMessage: "Error: " + twitterResponse.statusText
            });
          } 
        }
        else {
          const twitterData = await twitterResponse.json(); 
          //this.state.twitterAccounts = 0;
          for(const followers of twitterData){
            //this.state.twitterAccountDetails.push(followers);
            totalTwitterAccounts.push(followers);
            for(const user of followers.data){     
                this.state.twitterAccounts += user.public_metrics.followers_count;
            }
          }

          for(const t of totalTwitterAccounts){
            accountData.push(t.data);
            if(t.errors){
              accountErrors.push(t.errors);
            }
          }

          let mergedAccounts = [].concat.apply([], accountData);
          let mergedErrors = [].concat.apply([], accountErrors);

          accounts["data"] = mergedAccounts;
          if(accountErrors){
            accounts["errors"] = mergedErrors;
          }

          this.setState({
            showResults: true,
            validFile: true,
            recordCount: results.data.length,
            twitterAccounts: this.state.twitterAccounts,
          });

          setTimeout(
            () => {
              this.setState({loadingFile: false});
              getFileFollowerData(file,accounts, this.state.recordCount, this.state.twitterAccounts, results);
            }, 
            1000
          );
        }
      }
    }

    const convertFollowerData = async(results, file) => {
      let ids = [];
      let handles = [];
      let totalTwitterAccounts = [];
      let accounts = {};
      let accountData = [];
      let accountErrors = [];
      let twitterResponse;
      // Generate array of ids for Twitter API call
      // TODO - break out into own function 
      results.data.forEach((row, index) => {
        if(row['Follower ID']){
          row['Follower ID'] = row['Follower ID'].trim();
          ids.push(row['Follower ID']);
        }
        if(row['Twitter Handle']){
          row['Twitter Handle'] = row['Twitter Handle'].trim();
          handles.push(row['Twitter Handle']);
        }
      });

      // TO DO - Move to separate function 
      if(ids.length > 0 || handles.length > 0){
  
        // Get twitter data by IDs
        if(ids.length > 0){
          twitterResponse = await getTwitterFollowerData(ids);
        }
        // Get twitter data by handles 
        else {
          twitterResponse = await getTwitterFollowerDataByHandles(handles);
        }

        if(!twitterResponse.ok){
          this.setState({
            loadingFile: false,
            errorMessage: "Error: " + twitterResponse.statusText
          });
        } else {
          const twitterData = await twitterResponse.json();
          
          
          //this.state.twitterAccounts = 0;
          for(const followers of twitterData){
            //this.state.twitterAccountDetails.push(followers);
            totalTwitterAccounts.push(followers);
            for(const user of followers.data){     
                this.state.twitterAccounts += user.public_metrics.followers_count;
            }
          }

          for(const t of totalTwitterAccounts){
            accountData.push(t.data);
            if(t.errors){
              accountErrors.push(t.errors);
            }
          }

          let mergedAccounts = [].concat.apply([], accountData);
          let mergedErrors = [].concat.apply([], accountErrors);

          accounts["data"] = mergedAccounts;
          if(accountErrors){
            accounts["errors"] = mergedErrors;
          }

          this.setState({
            showResults: true,
            validFile: true,
            recordCount: results.data.length,
            twitterAccounts: this.state.twitterAccounts,
          });

          setTimeout(
            () => {
              this.setState({loadingFile: false});
              getFileFollowerData(file,accounts, this.state.recordCount, this.state.twitterAccounts, results);
            }, 
            1000
          );
        }
      }
    }

    const validateFileData = async (results, fileHeaders) => {
      const reg = new RegExp('^[0-9]+$');
      for(const [index, row] of results.data.entries()){
        if(row['Follower ID']){
          let valid = reg.test(row['Follower ID']);
          if(!valid){
            this.setState({
              loadingFile: false,
              errorMessage: "Error: Record #" + (index + 2) + " does not have a valid Twitter ID. It can only contain numbers. Please fix this and try again."
            });
            return false;
          }
        }
      }
      return true;
    } 

    // Validate file has all 10 headers
    // Validate headers are in correct order
    const validateFileHeaders = async (headers, fileHeaders) => {
      return JSON.stringify(headers) === JSON.stringify(fileHeaders);
    }

     // Validate file has 2 headers
    // Validate headers are in correct order
    const validateFollowerDataFile = async (headers, fileHeaders) => {
      return JSON.stringify(headers) === JSON.stringify(fileHeaders);
    }

    // Validate file has Twitter ID or Handle column
    const validateConversionFile = async (headers, fileHeaders) => {
      return headers.some(r=> fileHeaders.indexOf(r) >= 0)
    }

    // Get Haystack settings in Contentful 
    // Returns API data limit per hour 
    const getHaystackSettings = async () => {
      return fetch(apiUrl + '/api/v1/getHaystackSettings');
    }

    // Get follower count for all accounts 
    // Twitter API accepts batches up to 99 and returns public_metrics
    const getTwitterFollowers = async (ids) => {
      let response = await fetch(apiUrl + '/api/v1/getTwitterFollowers', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ids})
      }).catch(err => 
        this.setState({
          loadingFile: false
        })  
      );
      return response;
    }

            // Get follower data
    // Twitter API accepts batches up to 99 and returns public_metrics
    const getTwitterFollowerDataByHandles = async (handles) => {
      let response = await fetch(apiUrl + '/api/v1/getTwitterFollowerDataByHandles', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({handles})
      }).catch(err => 
        this.setState({
          loadingFile: false
        })  
      );
      return response;
    }

    // Get follower data
    // Twitter API accepts batches up to 99 and returns public_metrics
    const getTwitterFollowerData = async (ids) => {
      let response = await fetch(apiUrl + '/api/v1/getTwitterFollowerData', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ids})
      }).catch(err => 
        this.setState({
          loadingFile: false
        })  
      );
      return response;
    }

    // Reset all state variables 
    const resetState = () => {
      this.setState({
        validFile: null,
        files: [],
        recordCount: 0,
        twitterAccounts: 0,
        dataLimit: 0,
        timeEstimate: 0,
        timeEstimateDays: 0,
        timeEstimateHours: 0,
        timeEstimateMinuts: 0,
        showResults: false,
        loadingFile: false,
        errorMessage: null,
        twitterAccountDetails: null
      });
    }

    let errorState = (error) => {
      this.setState({
        loadingFile: false,
        errorMessage: error,
      });
    }

    // Format time estimate into days and hours
    const formatTimeEstimate = (time) => {
      const days = Math.floor(time/24);
      const remainder = time % 24;
      const hours = Math.floor(remainder);
      this.setState({
        timeEstimateDays: days,
        timeEstimateHours: hours,
      });
    }
  }

  render() {

    const validFile = this.state.validFile;
    const showResults = this.state.showResults;
    const loadingFile = this.state.loadingFile;
    const uploadFunction = this.props.uploadFunction;

    return (

      <Dropzone onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => (
          <section className="container px-0">
            <div className="file-upload" {...getRootProps({})}>
              <div className="file-upload-container p-4 position-relative d-flex align-items-center justify-content-center">
              {!loadingFile && !showResults &&
                <div className="file-upload-form d-flex flex-column p-0 align-items-center justify-content-center position-relative">
                  <input {...getInputProps()} />
                  <img className="folder-icon mb-2" src={folderIcon} />
                  <span className="font-weight-bold text-center">Drag your .CSV file here <br /> to start</span>
                  <span className="file-upload-divider py-3 position-relative text-center d-flex align-items-center justify-content-center text-uppercase">or</span>
                  <span className="btn bg-brand-blue text-white font-weight-bold mt-1 position-relative">Browse Files</span>
                </div>
                }
                
                {!loadingFile && showResults &&
                <div className="file-stats d-flex flex-column col-12 p-0">
                  <h5 className="text-brand-blue text-uppercase my-2 col-12 px-0 font-weight-bold fade-up">Results</h5>
                  <p className="text-uppercase mb-3 col-12 px-0 font-weight-bold fade-up">{this.state.file.name}</p>
                  <div className="col-12 d-flex px-0 flex-wrap">
                  <div className="col text-left pl-0 fade-up fade-2">
                    <span className="d-flex align-top">
                      Validation
                      <span className="d-flex align-self-center ml-1" data-tip data-for='validation'><img className="info-icon" src={infoIcon} /></span>
                    </span>
                    <ReactTooltip id='validation' type='light' effect="solid" className="info-tooltip light-box-shadow-x">
                      <span>The uploaded file has <b>passed validation</b> if: <br /> 
                        <ul className="mt-2 pl-3 mb-0">
                          <li>The headers <b>match</b> the template</li>
                          <li>Data is in the <b>correct</b> format</li>
                          <li>Minimum of <b>one (1)</b> valid record</li>
                        </ul>
                      </span>
                    </ReactTooltip>
                    {validFile &&
                      <p className="font-weight-bold mt-1">Pass</p>
                    }
                  </div>
                  <div className="col text-left pl-0 fade-up fade-3">
                    <span className="d-flex align-top">
                      Records
                      <span className="d-flex align-self-center ml-1" data-tip data-for='records'><img className="info-icon" src={infoIcon} /></span>
                    </span>
                    <ReactTooltip id='records' type='light' effect="solid" className="info-tooltip light-box-shadow-x">
                      <span>The <b>total</b> number of records in the uploaded file.</span>
                    </ReactTooltip>
                    <p className="font-weight-bold mt-1">
                      <CountUp end={this.state.recordCount} separator="," duration={2} />
                    </p>
                  </div>
                  <div className="col text-left pl-0 fade-up fade-4">
                    <span className="d-flex align-top">
                      Followers
                      <span className="d-flex align-self-center ml-1" data-tip data-for='followers'><img className="info-icon" src={infoIcon} /></span>
                    </span>
                    <ReactTooltip id='followers' type='light' effect="solid" className="info-tooltip light-box-shadow-x">
                      <span>The <b>total</b> number of followers for uploaded accounts.</span>
                    </ReactTooltip>
                    <p className="font-weight-bold mt-1">
                      <CountUp end={this.state.twitterAccounts} separator="," duration={2} />
                    </p>
                  </div>
                  <div className="col text-left px-0 fade-up fade-5">
                    <span className="d-flex align-top">
                      Estimate
                      <span className="d-flex align-self-center ml-1" data-tip data-for='estimate'><img className="info-icon" src={infoIcon} /></span>
                    </span>
                    <ReactTooltip id='estimate' type='light' effect="solid" className="info-tooltip light-box-shadow-x">
                      <span>Estimate is <b>calculated</b> by retrieving the <b>total accounts</b> and <b>dividing</b> by our current <b>API limit ({this.state.dataLimit.toLocaleString()} per hour)</b>.</span>
                    </ReactTooltip>
                    <div className="font-weight-bold mt-1">
                    {this.state.timeEstimateDays > 1 &&
                    <div className="d-flex">
                      <div>
                        <CountUp end={this.state.timeEstimateDays} duration={2} />
                        {this.state.timeEstimateDays === 1 &&
                          <span> day</span>
                         }
                        {this.state.timeEstimateDays > 1 &&
                        <span> days</span>
                        }
                      </div>
                      <div>
                        <CountUp end={this.state.timeEstimateHours} duration={2} className="ml-1" />
                        {this.state.timeEstimateHours === 1 &&
                          <span> hour</span>
                         }
                        {this.state.timeEstimateHours > 1 &&
                        <span> hours</span>
                        }
                      </div> 
                    </div>
                    }
                    {this.state.timeEstimateDays < 1 && this.state.timeEstimateHours > 1 &&
                      <div>
                        <CountUp end={this.state.timeEstimateHours} duration={2} />
                        {this.state.timeEstimateHours === 1 &&
                          <span> hour</span>
                         }
                        {this.state.timeEstimateHours > 1 &&
                        <span> hours</span>
                        }
                      </div>
                    }
                    {this.state.timeEstimateHours < 1 &&
                      <span><CountUp end={this.state.timeEstimateMinutes} duration={2} /> 
                        {this.state.timeEstimateMinutes > 1 &&
                          <span> minutes</span>
                        }
                        {this.state.timeEstimateMinutes == 1 &&
                          <span> minute</span>
                        }
                      </span>
                    }
                    </div>
                  </div>
                  </div>
                  {uploadFunction === 'follower-ids' &&
                  <div className="col-12 px-0 mt-3 border p-4 bg-white fade-up fade-6">
                  <div className="col text-center px-0">
                    <span className="d-flex align-top justify-content-center">
                      Recommended Action
                      <span className="d-flex align-self-center ml-1" data-tip data-for='action'><img className="info-icon" src={infoIcon} /></span>
                    </span>
                    <ReactTooltip id='action' type='light' effect="solid" className="info-tooltip light-box-shadow-x">
                      <span>The current <b>recommended</b> action is based on the <b>time estimate</b>. If the estimate is <b>under 72 hours</b> you can submit via <b>Haystack</b> otherwise please use the <b>Formative Help Desk</b>.</span>
                    </ReactTooltip>
                    <p className="font-weight-bold mt-1 mb-0">
                        {this.state.timeEstimate < 4320 &&
                          <NavLink tag={Link} to="/submit" className="">Submit via Haystack App</NavLink>
                        }
                        {this.state.timeEstimate > 4320 &&
                        <a rel="noopener noreferrer" className="nav-link" href="https://formativeco.atlassian.net/servicedesk/customer/portal/1/group/3" target="_blank">Submit via Formative Help Desk</a>
                        }
                    </p>
                  </div>
                  </div>
                  }
                  <div className="col-12 text-center fade-up fade-7">
                    <p className="mt-4 font-italic">Drag another file here to start.</p>
                  </div>
                </div>  
                }
                {loadingFile &&
                  <div className="loader-container d-flex flex-column h-100 p-4 text-center justify-content-center align-items-center">
                    <h5 className="font-weight-bold mt-4">Please wait. We are working hard <br /> to process your file.</h5>
                    <Loader />
                  </div>
                }
                </div>
                {this.state.errorMessage != null &&
                <div className="error pt-4">
                  <p className="text-danger mb-0 font-italic font-weight-bold">{this.state.errorMessage}</p>
                </div>
                }
            </div>
          </section>
        )}
      </Dropzone>
    );
  }
}


export default FileUpload;
