import React, { Component } from 'react';
import JobForm from '../components/job-form';
import { config } from '../Config';
import Loader from '../components/loader';
import DataTable from 'react-data-table-component';
import templateFile from '../assets/files/template.csv';
import infoIcon from '../assets/images/info.png';
import ReactTooltip from 'react-tooltip';
const apiUrl = config.url.API_URL;
const apiKey = config.url.API_KEY;

export class Job extends Component {
    
  constructor(props) {

    super(props);

    this.state = {
      jobData: null,
      pendingJobs: null,
      runningJobs: null,
      loading: true
    }

    //let jobData = this.props.jobData;

  }

  componentDidMount() {
    this.getJobs();
  }

    getJobs() {
      fetch(apiUrl + '/tasks/all?key=' + apiKey)
        .then(res => res.json())
        .then((data) => {

            data.tasks.sort(function(a,b){
              return new Date(b.startdate) - new Date(a.startdate);
            });

            data = this.formatData(data.tasks);

            const pendingJobs = data.filter(obj => {
              return obj.status === 'Pending';
            });

            const runningJobs = data.filter(obj => {
              return obj.status === 'Running';
            });
            this.setState({
              jobData: data.tasks,
              runningJobs: runningJobs,
              pendingJobs: pendingJobs,
              loading: false
            });
            
        })
        .catch((error) => {
          this.setState({
            loading: false
          });
        });
    }

    formatData(items) {

      items.forEach((item) => {

          // // Event Date Day ( 2 Digit )
          if (item["startdate"]) {
              item["startdate"] = new Intl.DateTimeFormat('en-US', {month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(item["startdate"]));
          }

          // Create Job Name 
          if(item["projectcode"]) {
            item["projectcode"] = item["projectcode"] + " " + item["jobcode"];
          }

          // Calculate and format est run time 
          if(item["runtime"]){
            
            item["runtime"] = this.calculateJobTime(item);
          }

      });

      return items;
    }

    calculateJobTime(job){

      let minutes = job["runtime"];
      let minutesLeft = 0;
      let hoursLeft = 0;
      let daysLeft = 0;
      let estRunTime = "";

      if(minutes > 1440){
        let time = minutes / 60;
        let days = Math.floor(time/24);
        let remainder = time % 24;
        let hours = Math.floor(remainder);
        let hoursRemainder = remainder % 60;
        let tempMinutes = Math.floor(hoursRemainder);
        daysLeft = days;
        hoursLeft = hours;
        minutesLeft = tempMinutes;
        estRunTime = daysLeft + "d " + hoursLeft + "h " + minutesLeft + "m";
      } 
      if (minutes > 60 && minutes <= 1440) {
        let hours = Math.floor(minutes / 60);
        let remainder = minutes % 60;
        let tempMinutes = Math.floor(remainder);
        daysLeft = 0;
        hoursLeft = hours;
        minutesLeft = tempMinutes;
        estRunTime = hoursLeft + "h " + minutesLeft + "m";
      }
      if (minutes < 60)  {
        daysLeft = 0;
        hoursLeft = 0;
        minutesLeft = minutes;
        estRunTime = minutesLeft + "m";
      }

      return estRunTime;

    }


  viewJob = (job) => {
    this.props.history.push(`/job/` + job.id);
  }

  jobSubmit = () => {
    this.getJobs();
  }

  render () {

    const columns = [
      {
          name: 'Job',
          selector: row => row.projectcode,
          sortable: true,
          tooltip: true
          // cell:(row) => row.projectcode + " " +  row.jobcode,
      },
      {
          name: 'Owner',
          selector: row => row.submittedby,
          sortable: true
      },
      {
        name: '~ Run Time',
        selector: row => row.runtime,
        sortable: true
      },
  ];

  let contents = this.state.loading
  ? 
  <div className="loader-container d-flex flex-column h-100 p-4 text-center justify-content-center align-items-center">
    <Loader />
  </div>
  :
  <div className="row">
  <div className="col-12 col-md-10 col-lg-8">
    <h4 className="text-uppercase font-weight-bold fade-up d-flex">
      Submit A Job
      <span className="d-flex align-self-center ml-2 p-1" data-tip data-for='info'><img className="info-icon" src={infoIcon} /></span>
    </h4>
    <ReactTooltip id='info' type='light' effect="solid" className="info-tooltip light-box-shadow-x" place="bottom" clickable={true}>
    <span className="h5 h-border text-uppercase font-weight-bold">Requirements<br /></span>
    <ul className="mt-2 pl-3 mb-3">
      <li>The headers <b>match</b> the template
        <ul className="my-2 pl-3 mb-0">
          <li>Followerid</li>
          <li>account id</li>
          <li>Twitter Handle</li>
          <li>Client</li>
          <li>Project</li>
          <li>Platform</li>
          <li>Handle_Type</li>
          <li>Date_Pulled</li>
          <li>Inf_Type</li>
          <li>Inf_Lane</li>
        </ul>
      </li>
      <li>Data is in the <b>correct</b> format</li>
      <li>Minimum of <b>one (1)</b> valid record</li>
    </ul>
    <span className="h5 h-border text-uppercase font-weight-bold">Limits: <br /></span>
    <ul className="mt-2 pl-3 mb-0">
      <li>260,0000 records / hour</li>
    </ul>
    <a href={templateFile} download className="btn bg-brand-blue w-100 text-white font-weight-bold position-relative mt-4 mb-0">Download Template File</a>
  </ReactTooltip>
    <p className="fade-up fade-1">Fill out the form below to submit your new job.</p>
  </div>

  <div className="col-12 col-md-10 col-lg-8">
    <div className="fade-up fade-3 bg-white p-0 light-box-shadow-x">
      <JobForm jobSubmit={this.jobSubmit} />
    </div>
  </div>
  <div className="col-12 col-lg-4 mt-4 pt-4 pt-lg-0 mt-lg-0">
    <h4 className="text-uppercase font-weight-bold fade-up fade-4">Queue</h4>
    <div className="row">
    {this.state.runningJobs !=  null &&
        <div className="col-12 mb-4">
            <h5 className="text-uppercase font-weight-bold fade-up fade-5">Running ({this.state.runningJobs.length})</h5>
            <div className="light-box-shadow-x mt-4 fade-up fade-6">
                <DataTable
                columns={columns}
                data={this.state.runningJobs}
                onRowClicked={this.viewJob}
                />
            </div>
      </div>
    }  
    {this.state.pendingJobs != null && 
      <div className="col-12 mb-4 pb-4">
          <h5 className="text-uppercase font-weight-bold fade-up fade-5">Pending ({this.state.pendingJobs.length})</h5>
          <div className="light-box-shadow-x mt-4 fade-up fade-6">
          <DataTable
              columns={columns}
              data={this.state.pendingJobs}
              onRowClicked={this.viewJob}
              />
          </div>
      </div>
    }
    {this.state.pendingJobs === null && !this.state.runningJobs === null &&
      <div className="col-12">
        <h5 className="fade-up fade-4 text-center font-italic py-4">There are no jobs to show.</h5>
      </div>
      }
  </div>
  </div>
</div>

    return (
      <div className="container-fluid mt-4">
        {contents}
      </div>
    );
  }
}
