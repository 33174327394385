import React, {Component} from 'react';
import styled from 'styled-components';

const Button = styled.button`
display: inline-block;
color: palevioletred;
font-size: 1em;
margin: 1em;
padding: 0.25em 1em;
border: 2px solid palevioletred;
border-radius: 3px;
display: block;
`;

const TomatoButton = styled(Button)`
color: tomato;
border-color: tomato;
`;


class Loader extends Component {

    render(){
        return (
            <div className="loader"><div></div><div></div><div></div><div></div></div>
        )
    }
    
}

export default Loader