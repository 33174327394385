import React, {Component} from 'react';
import FileUpload from '../components/file-upload';
import ReactTooltip from 'react-tooltip';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { config } from '../Config';
import Autocomplete from '../components/autocomplete';
import Loader from '../components/loader';

const apiUrl = config.url.API_URL;;
const apiKey = config.url.API_KEY;

class JobForm extends Component {
    constructor(props) {
      super(props);
      this.state = {
          projectCode: '',
          jobCode: '',
          cycle: '',
          submittedBy: '',
          jobtType: '',
          followers: 0,
          runTime: 0,
          file: null,
          loading: true,
          submitted: false,
          formError: false,
          totalAccounts: 0,
          users:null,
          jobCodes: [],
          jobNames: []
      };
  
      this.handleInputChange = this.handleInputChange.bind(this);
      this.submitForm = this.submitForm.bind(this);
      this.clearForm = this.clearForm.bind(this);
      let jobSubmit = this.props.jobSubmit;
      this.getUsers = this.getUsers.bind(this);
    }

    componentDidMount() {
      //this.getUsers();
      this.getJobCodes();
    }

    updateAutocompleteValue = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    getUsers = () => {
        fetch(apiUrl + '/users/all?key=' + apiKey, {
            method: 'get',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
          })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                  users: data,
                  loading: false
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false
                  });
            });
    }

    getJobCodes = () => {
        fetch(apiUrl + '/tasks/job-codes?key=' + apiKey, {
            method: 'get',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
          })
        .then(res => res.json())
        .then((data) => {
            this.setState({
                jobCodes: data['job_code'],
                jobNames: data['job_name'],
                loading: false
            });

            console.log('Job Codes: ', this.state.jobCodes);
            console.log('Job Names: ', this.state.jobNames);
        })
        .catch((error) => {
            this.setState({
                loading: false
                });
        });
    }
    
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        // if(name === "projectCode"){
        //     let valid = this.validateJobCode(value);
        //     if(!valid){

        //     }
        // }
        this.setState({
          [name]: value
        });
    }
  
    submitForm(event) {
      event.preventDefault();
      //const data = event.target.value;
    //   const data =  {
    //     "projectCode": this.state.projectCode,
    //     "jobCode": this.state.jobCode,
    //     "cycle": this.state.cycle,
    //     "submittedBy": this.state.submittedBy,
    //     "jobtType": this.state.jobtType,
    //     "followers": this.state.followers,
    //     "runtime": this.state.runTime,
    //     "file": this.state.file,
    // };
    let data  = new FormData();
    data.append("projectcode", this.state.projectCode.replace(/[^A-Z0-9]+/ig, "_"));
    data.append("jobcode", this.state.jobCode.toLowerCase().replace(/[^A-Z0-9]+/ig, "_"));
    data.append("cycle", this.state.cycle);
    data.append("submittedby", this.state.submittedBy);
    data.append("jobtype", this.state.jobtType);
    data.append("followers", this.state.followerCount);
    data.append("runtime", this.state.runTime);
    data.append("file", this.state.file);
    data.append("total_accounts", this.state.totalAccounts);

    // for(const name in event.target) {
    //     let key = event.target[name].name;
    //     let value = event.target[name].value;

    //   data.append(key, value);
    // }

    console.log('Form Data: ', data);

      fetch(apiUrl + '/tasks/upload?key=' + apiKey, {
        method: 'post',
        body: data
      })
      .then(res => res.json())
      .then((data) => {
        this.setState({ 
            submitted: true,
            loading: false 
        });

        this.props.jobSubmit();
      })
      .catch((error) => {
        this.setState({ 
            submitted: true,
            loading: false 
          });
      });
    }

    validForm() {
        const { projectCode, submittedBy, file, jobCode, cycle, jobtType } = this.state;
        let projectCodeRegex = /^\d\d-\d\d\d$/i;
        return (
          projectCodeRegex.test(projectCode) &&
          submittedBy.length > 0 && 
          projectCode.length > 0 &&
          jobCode.length > 0 &&
          cycle.length > 0 &&
          submittedBy.length > 0 &&
          jobtType.length > 0 &&
          file != null
        );
      }

    pushFile = (file, followers, runTime, records, followerCount) => {
        this.setState({
            file: file,
            followers: followers.length,
            runTime: runTime,
            totalAccounts: records,
            followerCount: followerCount
        });
    }

    setSelectedFile = (event) => {
        this.setState({
            file: event.target.files[0]
        })
    }

    clearForm(event) {

        event.preventDefault();
        //event.target.clearForm();
        this.setState = ({
            projectCode: '',
            jobCode: '',
            cycle: '',
            jobtType: '',
            followers: '',
            runTime: '',
            file: '',
            loading: false,
            submitted: false,
            formError: false,
            totalAccounts: 0
        });
    }
  
    render() {

      let isEnabled = this.validForm();
      let contents = this.state.loading
      ? 
      <div className="loader-container d-flex flex-column h-100 p-4 text-center justify-content-center align-items-center">
        <Loader />
      </div>
      :
        <div className="formContainer">
        {!this.state.submitted && 
        <form onSubmit={this.submitForm} className="d-flex flex-wrap p-4 w-100">
          <div className="col-12 col-md-8">
              <div className="d-flex flex-wrap">
                  <div className="d-flex col-12 col-md-6 p-0">
                  <label className="d-flex flex-column font-weight-bold job-form w-100">
                    Category or Job Code
                        {/* <input 
                            type="text" 
                            name="projectCode"
                            placeholder="e.g. 00-002"
                            defaultValue={this.state.projectCode} 
                            onChange={this.handleInputChange} 
                            className="mt-2" /> */}
                                 <Autocomplete 
                                    suggestions={this.state.jobCodes}
                                    name="projectCode"
                                    updateAutocompleteValue = {this.updateAutocompleteValue}
                                    placeholder="e.g. 00-002"
                                    className="pl-2 text-left"
                                 />
                    </label>
                  </div>
                  <div className="d-flex col-12 col-md-6 p-0 pl-3">
                  <label className="d-flex flex-column font-weight-bold job-form w-100 position-relative">
                        Topic or Job Name
                            <Autocomplete 
                                suggestions={this.state.jobNames}
                                name="jobCode"
                                updateAutocompleteValue = {this.updateAutocompleteValue}
                                placeholder="e.g. Climate Internal"
                                />
                    </label>
                  </div>
              </div>
          <label className="d-flex flex-column font-weight-bold mt-2">
            Owner
            <select 
                value={this.state.submittedBy} 
                name="submittedBy"
                onChange={this.handleInputChange} 
                className="mt-2" >
                    <option>Select employee..</option>
                    <option value="Brandon Friar">Brandon Friar</option>
                    <option value="Brynn Tweeddale">Brynn Tweeddale</option>
                    <option value="Drew Warren">Drew Warren</option>
                    <option value="Eric Kaplan">Eric Kaplan</option>
                    <option value="Gernot Kalcher">Gernot Kalcher</option>
                    <option value="Rachel Lore">Rachel Lore</option>
                    <option value="Ross Burmeister">Ross Burmeister</option>
            </select>
            </label>
            </div>
            <div className="col-12 col-md-4">
            <label className="d-flex flex-column font-weight-bold job-form mb-3">
                Cycle
                <select 
                    value={this.state.cycle} 
                    name="cycle"
                    onChange={this.handleInputChange} 
                    className="mt-2" >
                        <option>Select cycle..</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                </select>
            </label>

            <label className="d-flex flex-column font-weight-bold job-form">
                Job Type
                <select 
                    value={this.state.jobtType} 
                    name="jobtType"
                    onChange={this.handleInputChange} 
                    className="mt-2" >
                        <option>Select job Type..</option>
                        <option value="Pull Follower IDs">Pull Follower IDs</option>
                </select>
            </label>
            <label className="d-none">
                        Followers
                        <input 
                            type="text" 
                            name="followers"
                            disabled={true}
                            defaultValue={this.state.followers} 
                            onChange={this.handleInputChange} 
                            className="mt-2" />
            </label>
            <label className="d-none">
                        Followers
                        <input 
                            type="text" 
                            name="followers"
                            disabled={true}
                            defaultValue={this.state.followers} 
                            onChange={this.handleInputChange} 
                            className="mt-2" />
            </label>
            <label className="d-none">
                        Records
                        <input 
                            type="text" 
                            name="total_accounts"
                            disabled={true}
                            defaultValue={this.state.totalAccounts} 
                            onChange={this.handleInputChange} 
                            className="mt-2" />
            </label>
            
        </div>
            <div className="col-12">
            <label className="d-flex flex-column font-weight-bold mt-2">
                Upload File
                <div className="mt-2">
                    <FileUpload pushFile = {this.pushFile} uploadFunction = {'follower-ids'} />
                </div>
            </label>
            </div>
            <div className="col-12">
            <input 
                disabled={!isEnabled}
                type="submit" 
                value="Submit"
                data-tip data-for='submit' 
                className="btn bg-dark text-white font-weight-bold d-flex mt-3 ml-auto position-relative align-self-start px-4"/>
                {!isEnabled &&
                <ReactTooltip id='submit' type='light' effect="solid" className="info-tooltip light-box-shadow-x">
                    <span>To submit a job you need to complete the following: <br /> 
                        <ul className="mt-2 pl-3 mb-0">
                            <li>Fill out the correct <b>project name</b></li>
                            <li>Select the <b>submittedBy</b> from the dropdown</li>
                            <li>Upload a <b>valid</b> file</li>
                        </ul>
                    </span>
                </ReactTooltip>
                }
            </div>
            {this.state.formError &&
                <span className="pt-4 text-deanger font-weight-bold">There was an error uploading your job.</span>
            }
        </form>
        }
        {this.state.submitted &&
            <div className="form-success d-flex flex-column p-4 w-100 align-items-center">
                <h3 className="py-4 fade-up">Your file was uploaded successfully.</h3>
                <div className="actions d-flex my-4">
                    <form onSubmit={this.clearForm}> 
                        <button type="submit" className="btn bg-brand-blue text-white font-weight-bold mr-4 fade-up fade-2">Submit Another Job</button>
                    </form>
                    <NavLink tag={Link} to="/queue" className="btn bg-dark text-white font-weight-bold fade-up fade-3">Go To Queue</NavLink>
                </div>
            </div>
            }
        </div>
        return (
            <div className="container-fluid mt-4">
                    {contents}
            </div>
            );
    }
}


export default JobForm;