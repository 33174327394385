import React, { Component } from 'react';
import { Route, Redirect, Switch} from 'react-router';
import { Layout } from './components/layout/layout';
import { Dashboard } from './pages/dashboard';
import { Nav } from './components/nav/nav';
import { Estimate } from './pages/estimate';
import { Queue } from './pages/queue';
import { Job } from './pages/job';
import { JobDetails } from './pages/job-details';
import './styles/custom.css';
import withClearCache from "./ClearCache";
import { FollowerData } from './pages/follower-data';
import { Conversions } from './pages/conversions';

import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./components/SignInButton";
import logo from './assets/images/formative-haystack.svg';




const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  const isAuthenticated = useIsAuthenticated();
  if(!isAuthenticated){
    return(
        <div className="login-container col-12 col-md-5">
            <div className="login">
                <div className="logo">
                   
                    <img src={logo} />
                </div>
                <h2 className="centerText">Haystack</h2>
                <p className="centerText">Please login in to access the system</p>
                <div className="loginbtn">
                { false ? <span>Signed In</span> : <SignInButton /> }
                </div>
            </div>
        </div>
    );

}else{
  return (
    <div className="d-flex wrapper">
    <Nav />
    <Layout>
          <Switch>
          <Route exact path='/' component={Dashboard} />
          <Route exact path='/estimate' component={Estimate} />
          <Route exact path='/submit' component={Job} />
          <Route exact path='/queue' component={Queue} />
          <Route
            path="/tools"
            render={({ match: { url } }) => (
              <>
                <Route exact path={`${url}/conversions`} component={Conversions} />
                <Route exact path={`${url}/follower-data`} component={FollowerData} />
                <Route exact path={`${url}/follower-ids`} component={Job} />
              </>
            )}
          />
          <Route path='/job/:jobID' component={JobDetails} />
          <Redirect from="*" to='/' />
          </Switch>
        {/* <Route path="/events/:eDayID" component={EventDetails} /> */}
    </Layout>
</div>
  );
  }
}

export default App;
