import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/Button";

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <Button variant="secondary" className="btn-login" onClick={() => handleLogin(instance)}><img src="https://images.ctfassets.net/vqm72qf5au7t/6QLMgvPcgMB7UP3E9BUDLz/6fd601081f6d3df1d09a9a812c3b337f/login.png" /></Button>
    );
}