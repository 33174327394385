import React, { Component, Link } from 'react';
import FileUpload from '../components/file-upload';
import DataTable from 'react-data-table-component';
import Loader from '../components/loader';
import { config } from '../Config';
import {CSVLink} from 'react-csv';
import downloadIcon from '../assets/images/down-arrow.png';
import templateFile from '../assets/files/handle-id-conversion-template.csv';
import infoIcon from '../assets/images/info.png';
import ReactTooltip from 'react-tooltip';
const apiUrl = config.url.API_URL;
const apiKey = config.url.API_KEY;
const clickable = true;

export class Conversions extends Component {
    static displayName = Conversions.name;

    constructor(props) {
      super(props);
      this.state = {
        file: null,
        followers: '',
        runTime: '',
        records: 0,
        followerCount: '',
        loading: true,
        fileOutputData: []
      };
    }

    componentDidMount() {
      this.setState({
        loading: false
      })
    }
  
    getFileFollowerData = (file, followers, records, followerCount, fileInputRecords) => {

      let outputData = [];
      followers.data.forEach((row, index) => {
        
        outputData.push({
          "Followerid": "",
          "account id": row['id'],
          "Twitter Handle": row['username'], 
          "Client": "",
          "Project": "",
          "Platform": "",
          "Handle_Type": "",
          "Date_Pulled": "",
          "Inf_Type": "",
          "Inf_Lane": "",
        });
      });

      this.setState({
          file: file,
          followers: followers,
          records: records,
          followerCount: followerCount,
          fileOutputData: outputData
      });
    }



  render () {

  const twitterAccountErrors = [
    {
      name: 'ID',
      selector: row => row.value,
      sortable: true
    },
    {
      name: 'Error',
      selector: row => row['title'],
      sortable: true
    },
  ]

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Show All',
  };

  const conditionalRowStyles = [
    {
      when: row => row['public_metrics']['followers_count'] > 100000,
      style: {
        backgroundColor: 'rgba(255, 193, 7, .5)',
        color: 'black',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    {
      when: row => row['public_metrics']['followers_count'] > 1000000,
      style: {
        backgroundColor: 'rgba(220, 53, 69, .5)',
        color: 'black',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];

  const twitterAccountColumns  = [
    {
        name: 'ID',
        selector: row => row.id,
        sortable: true,
        width: '115px'
    },
    {
      name: 'Name',
      selector: row => row['name'],
      sortable: true,
      width: '115px'
    },
    {
      name: 'Username',
      selector: row => row['username'],
      sortable: true,
      width: '115px'
    },
    {
      name: 'Verified',
      selector: row => row['verified'].toLocaleString(),
      sortable: true,
      width: '115px'
    },
    {
      name: 'Description',
      selector: row => row['description'],
      sortable: true,
      width: '115px'
    },
    {
      name: 'Location',
      selector: row => row['location'],
      sortable: true,
      width: '115px'
    },
    {
      name: 'Followers',
      selector: row => row['public_metrics']['followers_count'],
      sortable: true,
      width: '115px'
    },
    {
      name: 'Friends',
      selector: row => row['public_metrics']['following_count'],
      sortable: true,
      width: '115px'
    },
    {
      name: 'Listed',
      selector: row => row['public_metrics']['listed_count'],
      sortable: true,
      width: '115px'
    },
    {
      name: 'Joined',
      selector: row => row['created_at'],
      sortable: true,
      width: '115px'
    },
  ];

  const fileHeaders = [
    { label: "Followerid", key: "" },
    { label: "account id", key: "id" },
    { label: "Twitter Handle", key: "name" },
    { label: "Client", key: "" },
    { label: "Project", key: "" },
    { label: "Platform", key: "" },
    { label: "Handle_Type", key: "" },
    { label: "Inf_Type", key: "" },
    { label: "Inf_Lane", key: "" },
  ];

  let contents = this.state.loading
  ? 
  <div className="loader-container d-flex flex-column h-100 p-4 text-center justify-content-center align-items-center">
    <Loader />
  </div>
  :
  <div className="row">
  <div className="col-12 col-md-10 col-lg-6">
    <h4 className="text-uppercase font-weight-bold fade-up d-flex">
      Handle &amp; ID Conversions
      <span className="d-flex align-self-center ml-2 p-1" data-tip data-for='info'><img className="info-icon" src={infoIcon} /></span>
    </h4>
    <ReactTooltip id='info' type='light' effect="solid" className="info-tooltip light-box-shadow-x" place="bottom" clickable={true}>
    <span className="h5 h-border text-uppercase font-weight-bold">Requirements <br /></span>
    <ul className="mt-2 pl-3 mb-3">
      <li>The headers <b>match</b> the template
        <ul className="my-2 pl-3 mb-0">
          <li>Follower ID</li>
          <li>Twitter Handle</li>
        </ul>
      </li>
      <li>Data is in the <b>correct</b> format</li>
      <li>Minimum of <b>one (1)</b> valid record</li>
    </ul>
    <span className="h5 h-border text-uppercase font-weight-bold">Limits <br /></span>
    <ul className="mt-2 pl-3 mb-0">
      <li>356,400 records / hour</li>
    </ul>
    <a href={templateFile} download className="btn bg-brand-blue w-100 text-white font-weight-bold position-relative mt-4 mb-0">Download Template File</a>
  </ReactTooltip>
    <p className="fade-up fade-2">Upload a one column csv files that either contains handles or IDs and Haystack will add the second column for a complete data set.</p>
  </div>
  <div className="col-12 col-md-10 col-lg-8">
      <div className="fade-up fade-4 bg-white p-4 light-box-shadow-x">
        <FileUpload getFileFollowerData = {this.getFileFollowerData} uploadFunction = {'conversion'} />
      </div>
    </div>

    <div className="col-12">
    {this.state.followers &&
        <div className="file-records d-flex flex-column mt-4">
          <div className="file-records-stats row pb-4 mb-4 mt-0 mt-md-4">
            <div className="col-12 pb-2 mb-4 d-flex align-items-center fade-up fade-5">
              <h2 className="font-weight-bold mb-0">Estimate Details</h2>
            </div>
            <div className="col-12 col-md-4 fade-up fade-6">
              <div className="card bg-white px-4 py-3 light-box-shadow-x">
                <h4 className="font-weight-bold pt-2">Accounts Found</h4>
                <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.followers.data.length}</h5>
              </div>
            </div>
            <div className="col-12 col-md-4 fade-up fade-7">
            <div className="card bg-white px-4 py-3 light-box-shadow-x">
                <h4 className="font-weight-bold pt-2">Errors</h4>
                {this.state.followers.errors &&
                  <h5 className="text-danger font-weight-bold pb-2">{this.state.followers.errors.length}</h5>
                }
                {!this.state.followers.errors &&
                  <h5 className="text-danger font-weight-bold pb-2">0</h5>
                } 
              </div>
            </div>
            <div className="col-12 col-md-4 fade-up fade-8">
            <div className="card bg-white px-4 py-3 light-box-shadow-x">
                <h4 className="font-weight-bold pt-2">Followers</h4>
                <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.followerCount.toLocaleString()}</h5>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column">
          <div className="file-records d-flex flex-column col-12 px-0">
              <div className="d-flex align-items-start pb-4">
              <h3 className="font-weight-bold text-upperpcase fade-up fade-9">Accounts</h3>
                <div className="d-flex ml-4 guide flex-column fade-up fade-10">
                    <div className="d-flex align-items-center">
                      <span className="box box-medium mr-2"></span>
                      <span>&gt; 100,000 Followers</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="box box-large mr-2"></span>
                      <span>&gt; 1,00,000 Followers</span>
                    </div>
                </div>
                <CSVLink 
                  data={this.state.fileOutputData}
                  filename={this.state.file.name.replace(".csv", "") + "-converted.csv"}
                  className="btn bg-brand-blue btn-download text-white font-weight-bold d-flex align-items-center px-4 ml-auto light-box-shadow-x py-2 fade-up fade-10">Download File
                  <img className="ml-3" src={downloadIcon} />
                  </CSVLink>
                {/* <CSVDownload data={this.state.followers.data} target="_blank" /> */}
              </div>
              <div className="light-box-shadow-x twitter-table fade-up fade-11">
                    <DataTable
                    columns={twitterAccountColumns}
                    data={this.state.followers.data}
                    pagination
                    conditionalRowStyles={conditionalRowStyles}
                    paginationComponentOptions={paginationComponentOptions} 
                />
              </div>
          </div>
          {this.state.followers.errors &&
          <div className="file-records d-flex flex-column col-12 mt-4 fade-up fade-12 px-0">
              <h3 className="font-weight-bold text-upperpcase pb-4">Errors</h3>
              <div className="light-box-shadow-x twitter-table">
                  <DataTable
                  columns={twitterAccountErrors}
                  pagination
                  data={this.state.followers.errors}
              />
              </div>
          </div>
          }
          </div>
        </div>
      }
    </div>
  </div>

    return (
      <div className="container-fluid mt-4">
            {contents}
      </div>
    );
  }
}
