import React, { Component } from 'react';
import Loader from '../components/loader';
import Papa from 'papaparse';
import DataTable from 'react-data-table-component';
import { config } from '../Config';
import file from '../assets/files/athena-haystack-test.csv';
import {CSVLink} from 'react-csv';
import { Range } from 'react-range';
import downloadIcon from '../assets/images/down-arrow.png';
import copyIcon from '../assets/images/copy.png';


const apiUrl = config.url.API_URL;
const appApiUrl = config.url.APP_API_URL;
const apiKey = config.url.API_KEY;
const { AthenaClient, GetQueryResultsCommand } = require("@aws-sdk/client-athena");
const client = new AthenaClient({ region: "us-west-2" });

export class JobDetails extends Component {
    static displayName = JobDetails.name;

    constructor(props) {
      super(props);
      this.state = {
        jobs: [], 
        relatedJobs: [],
        loading: true,
        jobDetails: null,
        jobId: null,
        daysLeft: 0,
        hoursLeft: 0,
        minutesLeft: 0,
        endTime: null,
        jobStatus: null,
        followers: null,
        fileRecords: null,
        twitterAccounts: null,
        twitterErrors: null,
        totalLines: 0,
        lastAccount: 0,
        queryResultsExecutionId: null,
        loadingOverlap: false,
        overlapError: false,
        savedOverlapData: null,
        loadingOverlapFile: false,
        overlapDownloadLink: null,
        values: [1],
        minValue: 1,
        maxValue: 1,
        originalOverlapData: null,
        overlapCount: 0,
        loadingResultsByCount: false,
        filterChanged: false,
        loadingOverlapDataTwitter: false,
        twitterOutputData: null,
        errorMessage: null,
        addTwitterData: false,
        overlapRelationships: 0,
        overlapRelationships2: 0,
        overlapUnique2: 0,
        uniqueFollowers: 0,
        overlapDensity2: 0,
        overlapDensity: 0,
        checkedPublishFilteredData: false,
        checkedCustomPublishFilteredData: false,
        publishFilteredDataFilename: null,
        filenamePlaceholder: 'Category>_<Topic>_<list_count>_<year-mo>',
        loadingPublishFile: false,
        publishMessage: null,
        loadingCustomPublishFile: false,
        customPublishMessage: null,
        eTag: null,
        publishedFileUrl: null
      };

      this.viewJob = this.viewJob.bind(this);
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        this.setState({jobId: this.props.match.params.jobID});
        this.getJobs();
        this.getProgress(this.props.match.params.jobID);
    }

    getProgress = (id) => {

      fetch(apiUrl + '/tasks/status/' + parseInt(id) + "?key=" + apiKey, {
            method: 'get',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
          })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                  totalLines: data.task_total_lines - 1,
                  lastAccount: data.task_current_account[1],
                })
            })
            .catch(console.log);
    }

    getOverlapResultsNew = async () => {
      this.setState({
        loadingOverlap: true,
        overlapError: false
      });
      fetch(appApiUrl + '/api/v1/createNewTableQuery', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.jobDetails)
      })
      .then(response => response.json())
      .then(data => {
  
          if(data.QueryExecutionId){
            // Get Query results
            this.createOverlapQuery();
          }
          // data.Rows = data.Rows.slice(1);
          // this.setState({ 
          //   overlapData: data.Rows
          // });
        
      })
      .catch(error => {
  
        this.setState({
          loadingOverlap: false
        });
      })
    }


    saveOverlapResults = async () => {
      this.setState({
        loadingOverlap: true,
        overlapError: false
      });
      fetch(appApiUrl + '/api/v1/saveOverlapResultsWithoutOverlap', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.jobDetails)
      })
      .then(response => response.json())
      .then(data => {

          if(data.QueryExecutionId){
            // Get Query results
            window.localStorage.setItem("QueryExecutionId", data.QueryExecutionId);
            let link = "https://formative-haystack.s3.us-west-2.amazonaws.com/" + this.state.jobDetails.projectcode.replace(/[^A-Z0-9]+/ig, "_") + '+-+' + this.state.jobDetails.jobcode.toLowerCase().replace(/[^A-Z0-9]+/ig, "_") + '/' + this.state.jobDetails.cycle + '/output/queries/' + data.QueryExecutionId + '.csv';
            this.setState(prevState => ({
                jobDetails: {                  
                    ...prevState.jobDetails,    
                    QueryExecutionId: data.QueryExecutionId,
                    OverlapResultsQueryExecutionId: data.QueryExecutionId,
                }
            }));

            setTimeout(() => {
              this.setState({
                overlapDownloadLink: link
              })
            }, 1000)

          }
          
        
      })
      .catch(error => {
      
        this.setState({
          loadingOverlap: false
        });
      })
    }

    saveOverlapResultsByCount = async () => {

      this.setState({
        loadingResultsByCount: true,
      });

      fetch(appApiUrl + '/api/v1/saveOverlapResultsByCount', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.jobDetails)
      })
      .then(response => response.json())
      .then(data => {

          if(data.QueryExecutionId){
            // Get Query results
            window.localStorage.setItem("QueryExecutionId", data.QueryExecutionId);
            let link = "https://formative-haystack.s3.us-west-2.amazonaws.com/" + this.state.jobDetails.projectcode.replace(/[^A-Z0-9]+/ig, "_") + '+-+' + this.state.jobDetails.jobcode.toLowerCase().replace(/[^A-Z0-9]+/ig, "_") + '/' + this.state.jobDetails.cycle + '/output/queries/' + data.QueryExecutionId + '.csv';
            this.setState(prevState => ({
                jobDetails: {                  
                    ...prevState.jobDetails,    
                    QueryExecutionId: data.QueryExecutionId      
                }
            }))

            setTimeout(() => {

              this.setState({
                overlapDownloadLink: link,
                loadingResultsByCount: false
              })

            }, 1000);

          }
          
        
      })
      .catch(error => {
        console.log('error'. error);
        this.setState({
          loadingResultsByCount: false
        });
      })
    }

    createOverlapQuery = async () => {
      fetch(appApiUrl + '/api/v1/createOverlapQuery', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.jobDetails)
      })
      .then(response => response.json())
      .then(data => {
  
          if(data.QueryExecutionId){
            // Get Query results
            this.getOverlapQueryResults(data.QueryExecutionId);
            this.setState({
              queryResultsExecutionId: data.QueryExecutionId
            });
          }
          // data.Rows = data.Rows.slice(1);
          // this.setState({ 
          //   overlapData: data.Rows
          // });
        
      })
      .catch(error => {
        console.log('error'. error);
        this.setState({
          loadingOverlap: false
        });
      });
    }

    saveTwitterOverlapFile = async () => {

      this.setState({
        loadingOverlap: true,
        loadingOverlapFile: true
      });
      fetch(appApiUrl + '/api/v1/saveResultsFile', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.jobDetails),
      })
      .then(response => response.json())
      .then(data => {
        if(data.ErrorCode){
          if(data.message.includes('FAILED')) {
            //if(data.ErrorCode === 'INVALID_QUERY_EXECUTION_STATE'){
              this.setState({
                loadingOverlapDataTwitter: false
              });
          } else {
            setTimeout(() => {
              this.saveTwitterOverlapFile();
            }, 50)
          }
        } else {
        this.getOverlapTwitterFollowerData(data);
        }
      }).catch(error => {
        console.log('Error:', error); 
        this.setState({
          loadingOverlapDataTwitter: false,
          errorMessage: error.message
        })

      })
    }

    getOverlapQueryResults = async (id) => {
      this.setState({
        loadingOverlap: true
      });
      fetch(appApiUrl + '/api/v1/getTableQueryResults', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({id})
      })
      .then(response => response.json())
      .then(data => {
        
      if(data.ErrorCode){
        if(data.message.includes('FAILED')) {
          //if(data.ErrorCode === 'INVALID_QUERY_EXECUTION_STATE'){
            this.setState({
              loadingOverlap: false,
              overlapError: true
            });
        } else {
          setTimeout(() => {
            this.getOverlapQueryResults(this.state.queryResultsExecutionId);
          }, 50)
        }
      } else {
        if(data.ResultSet.Rows.length > 1){

          data.ResultSet.Rows = data.ResultSet.Rows.slice(1);
          let values = [];
        
          for(const item of data.ResultSet.Rows){
              let count = parseInt(item.Data[0]['VarCharValue']);
              values.push(count);
          }
  
          var min = Math.min( ...values ),
          max = Math.max( ...values );
  
          let overlapCount = 0; 
          let overlap = 0;
          let overlapRelationships = 0;
          let overlapRelationships2 = 0;
          let uniqueFollowers = 0;
          let overlapUnique2 = 0;
          let overlap1 = 0;
          let overlapDensity = 0;
          let overlapDensity2 = 0;
  
          data.ResultSet.Rows.forEach((item) => {
            overlapCount += parseInt(item.Data[1]['VarCharValue']);
            overlap += parseInt(item.Data[0]['VarCharValue']);
            overlapRelationships += parseInt(item.Data[1]['VarCharValue']);

            if(parseInt(item.Data[0]['VarCharValue']) != 1){
              overlapRelationships2 += parseInt(item.Data[1]['VarCharValue']);
            }

          });

          let lastItem = data.ResultSet.Rows.slice(-1)[0];
          overlap1 = parseInt(lastItem.Data[1]['VarCharValue'])
          

          overlapRelationships += overlap;
          overlapRelationships2 += overlap;
          uniqueFollowers = overlapCount;
          overlapUnique2 = uniqueFollowers - overlap1;
          overlapDensity = overlapRelationships / uniqueFollowers;
          overlapDensity = Math.round((overlapDensity + Number.EPSILON) * 100) / 100;
          overlapDensity2 = overlapRelationships2 / overlapUnique2;
          overlapDensity2 = Math.round((overlapDensity2 + Number.EPSILON) * 100) / 100;
  
          this.setState({ 
            overlapData: data.ResultSet.Rows,
            originalOverlapData: data.ResultSet.Rows,
            maxValue: max,
            overlapCount: overlapCount,
            overlapRelationships: overlapRelationships,
            overlapRelationships2: overlapRelationships2,
            overlapUnique2: overlapUnique2,
            overlapDensity2: overlapDensity2,
            overlapDensity: overlapDensity
          });
  
          this.saveOverlapResults();
        } else {
          this.setState({ 
            maxValue: 1,
            overlapCount: 0,
            loadingOverlap: false,
            errorMessage: "We couldn't find any records."
          });
        }

      }
        
      }).catch(error => {
        console.log('error'. error);

        if(error.ErrorCode != 'INVALID_QUERY_EXECUTION_STATE'){
          this.setState({
            loadingOverlap: false
          });
        } else {
          setTimeout(() => {
            this.getOverlapQueryResults(this.state.queryResultsExecutionId);
          }, 50)
        }

      })
      .finally(data => {

      })
    }

    getTwitterOverlapQueryResults = async (id) => {
      this.setState({
        loadingOverlap: true
      });
      fetch(appApiUrl + '/api/v1/getTableQueryResults', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({id})
      })
      .then(response => response.json())
      .then(data => {
        
      if(data.ErrorCode){
        if(data.message.includes('FAILED')) {
          //if(data.ErrorCode === 'INVALID_QUERY_EXECUTION_STATE'){
            this.setState({
              loadingOverlapDataTwitter: false
            });
        } else {
          setTimeout(() => {
            this.getTwitterOverlapQueryResults(id);
          }, 50)
        }
      } else {

        data.ResultSet.Rows = data.ResultSet.Rows.slice(1);
        
        // to do 

        this.getOverlapTwitterFollowerData(data.ResultSet);
      }
        
      }).catch(error => {
        console.log('error'. error);

        if(error.ErrorCode != 'INVALID_QUERY_EXECUTION_STATE'){
          this.setState({
            loadingOverlapDataTwitter: false
          });
        } else {
          setTimeout(() => {
            this.getTwitterOverlapQueryResults(id);
          }, 50)
        }

      })
      .finally(data => {

      })
    }

    updateOverlapData = (value) => {

      let newData = this.state.originalOverlapData.filter(function (item) {
        return parseInt(item.Data[0]['VarCharValue']) >= value.values[0]
      });

      let overlapCount = 0;



      this.setState({
        overlapData: newData,

        filterChanged: true
      });

      this.setState(prevState => ({
          jobDetails: {                  
              ...prevState.jobDetails,    
              OverlapCount: value.values[0]      
          }
      }));

      let overlap = 0;
      let overlapRelationships = 0;
      let overlapRelationships2 = 0;
      let uniqueFollowers = 0;
      let overlapUnique2 = 0;
      let overlap1 = 0;
      let overlapDensity = 0;
      let overlapDensity2 = 0;

      console.log('New data: ', newData);
      console.table(newData);

      newData.forEach((item) => {
        overlapCount += parseInt(item.Data[1]['VarCharValue']);
        overlap += parseInt(item.Data[0]['VarCharValue']);
        overlapRelationships += parseInt(item.Data[1]['VarCharValue']);

        if(parseInt(item.Data[0]['VarCharValue']) != 1){
          overlapRelationships2 += parseInt(item.Data[1]['VarCharValue']);
        }

        if(parseInt(item.Data[0]['VarCharValue']) === 1){
          overlap1 += parseInt(item.Data[1]['VarCharValue'])
        }

      });

      // let lastItem = data.ResultSet.Rows.slice(-1)[0];
      // overlap1 = parseInt(lastItem.Data[1]['VarCharValue'])
      // console.log('Overlap 1: ' + overlap1);

      overlapRelationships = overlapCount + overlap;
      overlapRelationships2 = (overlapCount + overlap) - overlap1;
      uniqueFollowers = overlapCount;
      overlapUnique2 = uniqueFollowers - overlap1;
      overlapDensity = overlapRelationships / uniqueFollowers;
      overlapDensity = Math.round((overlapDensity + Number.EPSILON) * 100) / 100;
      overlapDensity2 = overlapRelationships2 / overlapUnique2;
      overlapDensity2 = Math.round((overlapDensity2 + Number.EPSILON) * 100) / 100;

      // console.log('Overlap Relationships: ' + overlapRelationships);
      // console.log('Overlap Relationships 2: ' + overlapRelationships2);
      // console.log('Unique Followers: ' + uniqueFollowers);
      // console.log('Unigue Followers 2: ' + overlapUnique2);
      // console.log('Density: ' + overlapDensity);
      // console.log('Density 2 ' + overlapDensity2);
      // console.log('____________________________________________________');

      this.setState({ 
        overlapRelationships: overlapRelationships,
        overlapRelationships2: overlapRelationships2,
        overlapUnique2: overlapUnique2,
        overlapDensity2: overlapDensity2,
        overlapDensity: overlapDensity,
        overlapCount: overlapCount
      });
    
    }

    cancelJob = () => {
        fetch(apiUrl + '/tasks/end/' + parseInt(this.state.jobId) + "?key=" + apiKey, {
            method: 'get',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
          })
            .then(res => res.json())
            .then((data) => {
                this.setState({
                  jobStatus: 'Cancelled',
                  daysLeft: 0,
                  hoursLeft: 0,
                  minutesLeft: 0,
                  endTime: null
                })
            })
            .catch(console.log);
    }

    getJobFile = () => {
      fetch(apiUrl + '/tasks/uploads/' + this.state.jobDetails.filename + "?key=" + apiKey, {
          method: 'get',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
        })
        .then( res => res.blob())
        .then( blob => {
                   // Read and parse csv file
                   Papa.parse(blob, {
                    worker: true,
                    header: true,
                    complete: (results) => {
                      // Validate data in file 
                      this.setState({
                        fileRecords: results.data
                      });
                      this.getTwitterData(results.data);
                      //this.getOverlapResults();
                    }
                });
        })
        .catch((error) => {
          this.setState({
              loading: false
          });
        });
  }

  getTwitterData = async (results) => {
    let handles = [];
    let twitterAccounts = [];
    let twitterErrors = [];
    results.forEach((row, index) => {

      if(row['Twitter Handle']){
        row['Twitter Handle'] = row['Twitter Handle'].replaceAll('"','');
        handles.push(row['Twitter Handle']);
      }
    });

       // Get Overlap data 
      //const getOverlapResponse = await this.getOverlapResults();

        
      // Get twitter data 
      this.getTwitterFollowers(handles);
  }

  getOverlapTwitterFollowerData = async (data) => {
    let twitterAccounts = [];
    let twitterErrors = [];

       // Get Overlap data 
      //const getOverlapResponse = await this.getOverlapResults();

      let twitterIds = [];

        
      
      for(const item of data){
          let tempId = parseInt(item['follower_id']);
          //Twitter API Regex - ^[A-Za-z0-9_]{1,15}$
          let validTwitterId = /^[A-Za-z0-9_]{1,15}$/.test(tempId);

          if(validTwitterId){
            twitterIds.push(tempId);
          }
          
      }
        
      // Get twitter data 
      const twitterResponse = await this.getTwitterFollowerOverlapData(twitterIds);
      //const twitterResponse = null;
      if(!twitterResponse.ok){
        //TO DO ERROR
        this.setState({
          loadingOverlapDataTwitter: false
        });
      }  else {
        const twitterData = await twitterResponse.json();
        
        
        let followerCount = 0;
        //this.state.twitterAccounts = 0;
        for(const followers of twitterData){
          //this.state.twitterAccountDetails.push(followers);
          
          if(followers.data){
            for(const user of followers.data){    
              twitterAccounts.push(user); 
              followerCount += user.public_metrics.followers_count;
            }
          }
          if(followers.errors){
            for(const error of followers.errors){    
              twitterErrors.push(error); 
            }
          }
        }

        let outputData = [];

        // let arr3 = twitterAccounts.map((item, i) => Object.assign({}, item, data[i]));

        // console.log('Result: ', arr3);

        let merged = [];

        for(let i=0; i<twitterAccounts.length; i++) {
          merged.push({
          ...twitterAccounts[i], 
          ...(data.find((itmInner) => itmInner['follower_id'] === twitterAccounts[i]['id']))}
          );
        }

        merged.forEach((row, index) => {

          console.log('Merged Row: ', row);

            outputData.push({
            "Overlap": row['Overlap'],
            "follower_id": row['id'],
            "name": row['name'], 
            "screen_name": row['username'],
            "verified": row['verified'] === true ? 'Yes' : 'No',
            "description": row['description'].replace(/(\r\n|\n|\r)/gm, "").trim(),
            "location": row['location'],
            "followers_count": row['public_metrics']['followers_count'],
            "following_count": row['public_metrics']['following_count'],
            "listed_count": row['public_metrics']['listed_count'],
            "tweet_count": row['public_metrics']['tweet_count'],
            "created_at": row['created_at']
          });
        });

      //   twitterAccounts.forEach((row, index) => {
        
      //   let inputRecord = data.filter(obj => {
      //     return obj['follower_id'] === row['id'];
      //   });

      //   if(inputRecord.length > 0){
      //     row['overlap'] = inputRecord[0]['Overlap'];

      //     outputData.push({
      //       "Overlap": inputRecord[0]['Overlap'],
      //       "follower_id": row['id'],
      //       "name": row['name'], 
      //       "screen_name": row['username'],
      //       "verified": row['verified'] === true ? 'Yes' : 'No',
      //       "description": row['description'].replace(/(\r\n|\n|\r)/gm, "").trim(),
      //       "location": row['location'],
      //       "followers_count": row['public_metrics']['followers_count'],
      //       "friends_count": row['public_metrics']['following_count'],
      //       "listed_count": row['public_metrics']['listed_count'],
      //       "created_at": row['created_at']
      //     });
      //   }
      // });

      this.setState({
        loadingOverlapDataTwitter: false,
        twitterOutputData: outputData
      });
      }
  }

  getTwitterOverlapData = async () => {

    this.setState({
      loadingOverlapDataTwitter: true
    });
    fetch(appApiUrl + '/api/v1/saveOverlapResultsByCount', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.jobDetails)
    })
    .then(response => response.json())
    .then(data => {

        if(data.QueryExecutionId){
          // Get Query results
          //this.getTwitterOverlapQueryResults(data.QueryExecutionId);  
          //change to twitter file
          this.setState(prevState => ({
            jobDetails: {                  
                ...prevState.jobDetails,    
                QueryExecutionId: data.QueryExecutionId      
            }
        }))

        // TODO 
        // make call to api 
        // S3 get object and rename 


        setTimeout(() => {
          this.saveTwitterOverlapFile();
        }, 3000)
        }
    })
    .catch(error => {
      this.setState({
        loadingOverlapDataTwitter: false
      });
    })
  }

  publishResults = async () => {

  this.setState(prevState => ({
      jobDetails: {                  
          ...prevState.jobDetails,    
          filteredDataFilename: this.state.publishFilteredDataFilename   
      },
      loadingCustomPublishFile: true
  }), this.publishResultsFile);
  
  }

  publishAllResults = async () => {

    this.setState(prevState => ({
        jobDetails: {                  
            ...prevState.jobDetails,    
            QueryExecutionId: this.state.OverlapResultsQueryExecutionId,
            filteredDataFilename: this.state.publishFilteredDataFilename   
        },
        loadingPublishFile: true
    }), this.publishAllResultsFile);
    
    }

    publishAllResultsFile = async () => {

      fetch(appApiUrl + '/api/v1/publishResultsFile', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.jobDetails)
      })
      .then(response => response.json())
      .then(data => {
  
        if(data.CopyObjectResult){
          if(data.CopyObjectResult.ETag){
            console.log('Published file successfully.');
            this.setState({
              publishMessage: 'The audience was published.',
              loadingPublishFile: false,
              publishedFileUrl: 'https://lists.haystackaudiences.com.s3.us-west-2.amazonaws.com/' + this.state.jobDetails.filteredDataFilename + '.csv'
            });
          }
        }
      })
      .catch(error => {
  
        // TODO - Publish File loading Fail
        this.setState({
          publishMessage: 'Error. Please try again.',
          loadingPublishFile: false
        });
      })
    }

  publishResultsFile = async () => {

      fetch(appApiUrl + '/api/v1/publishResultsFile', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.jobDetails)
      })
      .then(response => response.json())
      .then(data => {
  
        if(data.CopyObjectResult){
          if(data.CopyObjectResult.ETag){
            console.log('Published filed successfully.');

            this.setState({
              customPublishMessage: 'The custom audience was published.',
              loadingCustomPublishFile: false,
              publishedFileUrl: 'https://lists.haystackaudiences.com.s3.us-west-2.amazonaws.com/' + this.state.jobDetails.filteredDataFilename + '.csv'
            });

            
          }
        }
      })
      .catch(error => {
  
        // TODO - Publish File loading Fail
        this.setState({
          publishMessage: 'Error. Please try again.',
          loadingPublishFile: false
        });
      })
    }

    getPublishedFileData = async () => {

      fetch(appApiUrl + '/api/v1/getFileByName', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.jobDetails)
      })
      .then(response => response.json())
      .then(data => {
        console.log('Published file Data: ', data);
        if(data.CopyObjectResult){
          if(data.CopyObjectResult.ETag){
            console.log('Published file successfully.');

            this.setState({
              publishMessage: 'The audience was published.',
              loadingPublishFile: false,
              eTag: data.CopyObjectResult.ETag
            });
          }
        }
      })
      .catch(error => {
  
        // TODO - Publish File loading Fail
        this.setState({
          publishMessage: 'Error. Please try again.',
          loadingPublishFile: false
        });
      })
    }

  getOverlapResults = async () => {
    return fetch(appApiUrl + '/api/v1/getOverlapResults', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    }) .then(response => response.json())
    .then(data => {

      let values = [];
      
        for(const item of data){
            let count = parseInt(item.Data[0]['VarCharValue']);
            values.push(count);
        }

        var min = Math.min( ...values ),
        max = Math.max( ...values );

        data.Rows = data.Rows.slice(1);

        let overlapCount = 0; 

        data.Rows.forEach((item) => {
          overlapCount += parseInt(item.Data[1]['VarCharValue'])
        });

        this.setState({ 
          overlapData: data.Rows,
          originalOverlapData: data.Rows,
          overlapCount: overlapCount
        });

        
      
    });
  }

  handlePublishFilteredData = () => {

    let filename = this.state.jobDetails.jobcode.replace(/[^A-Z0-9]+/ig, "_") + '_' + this.state.overlapCount + '_' + new Date().getFullYear() + '-' + new Date().getMonth();
    this.setState({ 
      checkedPublishFilteredData: !this.state.checkedPublishFilteredData,
      checkedCustomPublishFilteredData: false,
      filenamePlaceholder: filename,
      publishFilteredDataFilename: filename
    });
  };

  handleCustomPublishFilteredData = () => {

    let filename = this.state.jobDetails.jobcode.replace(/[^A-Z0-9]+/ig, "_") + '_' + this.state.overlapCount + '_' + new Date().getFullYear() + '-' + new Date().getMonth();
    this.setState({ 
      checkedCustomPublishFilteredData: !this.state.checkedCustomPublishFilteredData,
      filenamePlaceholder: filename,
      publishFilteredDataFilename: filename,
      checkedPublishFilteredData: false
    });
  };

  handleFilenameChange = (e) => {

    let name = e.target.value;
    name = name.replace(/[^A-Z0-9]+/ig, "_");

    this.setState({
        publishFilteredDataFilename: name
    });
}


    // Get follower count for all accounts 
    // Twitter API accepts batches up to 99 and returns public_metrics
    getTwitterFollowers = async (ids) => {
     fetch(appApiUrl + '/api/v1/getTwitterFollowers?key=' + apiKey, {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ids})
      })
      .then(res => res.json())
      .then((data) => {
        let twitterAccounts = [];
        let twitterErrors = [];
        let followerCount = 0;
        //this.state.twitterAccounts = 0;
        for(const followers of data){
          //this.state.twitterAccountDetails.push(followers);
          
          for(const user of followers.data){    
            twitterAccounts.push(user); 
            followerCount += user.public_metrics.followers_count;
          }
          if(followers.errors){
            for(const error of followers.errors){    
              twitterErrors.push(error); 
            }
          }
        }

        console.log('Twitter Accounts: ', twitterAccounts);
        this.setState({
          followers: followerCount,
          twitterAccounts: twitterAccounts,
          twitterErrors: twitterErrors,
          loading: false
      });

      })
      .catch((error) => {
        this.setState({ 
          jobs: '',
          loading: false,
          pendingJobs: '',
          completedJobs: '',
          runningJobs: ''
        });
      });
    }

     // Get follower data
    // Twitter API accepts batches up to 99 and returns public_metrics
    getTwitterFollowerOverlapData = async (ids) => {
      return fetch(appApiUrl + '/api/v1/getTwitterFollowerData', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ids})
      });
    }

    getJobs() {
        fetch(apiUrl + '/tasks/all?key=' + apiKey)
            .then(res => res.json())
            .then((data) => {
                const job = data.tasks.find(obj => {
                    return obj.id === parseInt(this.state.jobId);
                });

                const relatedJobs = data.tasks.filter(obj => {
                  return parseInt(obj.projectcode) === parseInt(job.projectcode) && obj.jobcode === job.jobcode;
                });

                relatedJobs.sort(function(a,b){
                  return parseInt(a.cycle) - parseInt(b.cycle);
                });

                if(job.status === "Running"){
                  this.calculateJobTime(job);
                }
                if(job.status === "Pending"){
                  this.estimateJobTime(job);
                }
                if(job.status === "Done"){
                  this.calculateActualRunTime(job);
                } 

                if (job["enddate"] != null) {
                  job["enddate"] = new Intl.DateTimeFormat('en-US', {month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(job["enddate"]));
                } 

                this.setState({ 
                  jobs: data.tasks, 
                  jobDetails: job,
                  jobStatus: job.status,
                  relatedJobs: relatedJobs
                });

                this.setState(prevState => ({
                  jobDetails: {                  
                      ...prevState.jobDetails,    
                      OverlapCount: 1      
                  }
              }))

                

                this.getJobFile();

            })
            .catch((error) => {
              this.setState({ 
                jobs: '',
                loading: false,
                pendingJobs: '',
                completedJobs: '',
                runningJobs: ''
              });
            });
    }

    calculateActualRunTime(job){

      let startDate = new Date(job["startdate"]);
      let endDate   = new Date(job["enddate"]);
      let minutes = ((endDate.getTime() - startDate.getTime()) / 1000) / 60;

      if(minutes > 1){
        this.setState({
          daysLeft: Math.floor(minutes/24/60),
          hoursLeft: Math.floor(minutes/60%24),
          minutesLeft: Math.floor(minutes%60)
        });
      } else {
        this.setState({
          daysLeft: 0,
          hoursLeft: 0,
          minutesLeft: 1
        });
      }
    }



    estimateJobTime(job){

      const minutes = job["runtime"];

      this.setState({
        daysLeft: Math.floor(minutes/24/60),
        hoursLeft: Math.floor(minutes/60%24),
        minutesLeft: Math.floor(minutes%60)
      });

    }

    viewJob = (job) => {
      this.props.history.push(`/job/` + job.id);
      this.setState({jobId: job.id, loading: true});
      this.getJobs();
    }

    


    calculateJobTime(job){

      let diff = Math.abs(new Date() - new Date(job.startdate));
      let tempMinutes = Math.floor((diff/1000)/60);

      const minutes = job.runtime - tempMinutes;

      let currentDate = new Date();

      if(minutes > 0){
        currentDate.setTime(currentDate.getTime() + (minutes*60000));
      }

      currentDate = new Intl.DateTimeFormat('en-US', {month: '2-digit', day: 'numeric', hour: '2-digit', minute: '2-digit' }).format(new Date(currentDate));

      this.setState({
        daysLeft: Math.floor(minutes/24/60),
        hoursLeft: Math.floor(minutes/60%24),
        minutesLeft: Math.floor(minutes%60),
        endTime: currentDate
      });



      // const days = Math.floor(runTime/24);
      // const remainder = runTime % 24;
      // const hours = Math.floor(remainder);
      // const remainderHours = hours % 1440;
      // const minutes = Math.floor(remainderHours);
      
      // let submittedTime = new Date(job.startdate);
      // var diff = new Date(currentTime.getTime() - submittedTime.getTime());

      // var years = diff.getUTCFullYear() - 1970; // Gives difference as year
      // var months = diff.getUTCMonth(); // Gives month count of difference
      // var days = diff.getUTCDate()-1; // Gives day count of difference
    }


  render() {

    const twitterAccountErrors = [
      {
        name: 'ID',
        selector: row => row.value,
        sortable: true
    },
    {
        name: 'Error',
        selector: row => row['title'],
        sortable: true
    },
    ]

    const conditionalRowStyles = [
      {
        when: row => row['public_metrics']['followers_count'] > 100000,
        style: {
          backgroundColor: 'rgba(255, 193, 7, .5)',
          color: 'black',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
      {
        when: row => row['public_metrics']['followers_count'] > 1000000,
        style: {
          backgroundColor: 'rgba(220, 53, 69, .5)',
          color: 'black',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
      {
        when: row => row.id === this.state.lastAccount.toLocaleString() && this.state.jobStatus === "Running",
        style: {
          backgroundColor: 'rgba(40, 167, 69, 0.5)',
          color: 'black',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      }
    ];

    const paginationComponentOptions = {
      selectAllRowsItem: true,
      selectAllRowsItemText: 'Show All',
    };

    const overlapResultColumns  = [
      {
          name: 'Overlap',
          selector: row => parseInt(row.Data[0]['VarCharValue']),
          sortable: true
      },

      {
        name: 'Overlap Count',
        selector: row => parseInt(row.Data[1]['VarCharValue']),
        sortable: true
    }
    
  ]
  
    const twitterAccountColumns  = [
      {
          name: 'ID',
          selector: row => row.id,
          sortable: true
      },
      {
          name: 'Username',
          selector: row => row['username'],
          sortable: true
      },
      {
        name: 'Name',
        selector: row => row['name'],
        sortable: true
      },
      {
        name: 'Followers',
        selector: row => row['public_metrics']['followers_count'],
        sortable: true
      },
      {
        name: 'Tweets',
        selector: row => row['public_metrics']['tweet_count'],
        sortable: true
      }
    ];

    const columns = [
      {
          name: 'Cycle',
          selector: row => row.cycle,
          sortable: true,
          width: '100px'
      },
      {
          name: 'Submitted',
          selector: row => row.uploaddate,
          sortable: true
      },
      {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
        conditionalCellStyles: [
          {
            when: row => row.status === "Running" || "Done",
            style: {
              color: '#28a745'
            },
          },
          {
            when: row => row.status ===  "Done",
            style: {
              color: '#00A7D0'
            },
          },
          {
            when: row => row.status === "Pending",
            style: {
              color: '#ffc107'
            },
          },
        ],
      },
  ];

    let contents = this.state.loading
        ? 
        <div className="loader-container d-flex flex-column h-100 p-4 text-center justify-content-center align-items-center">
          <Loader />
        </div>
        :
        <div className="container-fluid mt-4">
        {this.state.jobDetails &&
            <div className="job-details">
                <div className="row">
                <div className="col-12 pb-2 mb-4 d-flex flex-column fade-up">
                  <h2 className="font-weight-bold mt-3">
                    {this.state.jobDetails.projectcode.replace(/[^A-Z0-9]+/ig, "_") + ' ' + this.state.jobDetails.jobcode.replace(/[^A-Z0-9]+/ig, " ")}
                    <span className="font-italic italic light-gray h5 pl-3"> Cycle: {this.state.jobDetails.cycle}</span>
                  </h2>
                  {this.state.jobStatus === "Running" &&
                    <button onClick={this.cancelJob} className="d-inline-flex text-danger border-0 bg-transparent align-self-start font-weight-bold p-0 mt-2">Cancel Job</button>
                  }                
                </div>
                <div className="col-12 mb-2">
                  <h3 className="font-weight-bold fade-up fade-1">Overlap Analysis</h3>
                  </div>
                <div className="d-flex flex-wrap align-items-start">
                <div className="col-12 col-md-6">
                    <div className="d-flex flex-column">
                       
                    {this.state.overlapData &&
                      <div className="col-12 mt-0 mb-5 pr-4 pl-0 fade-up fade-1">
                        <span className="font-weight-bold mb-4 pb-1 d-flex filter-overlap">Filter Overlap ({this.state.overlapCount.toLocaleString()})</span>
                        <Range
                          className="slider"
                          step={1}
                          min={1}
                          max={this.state.maxValue}
                          values={this.state.values}
                          onChange={(values) =>  {
                            this.setState({ values });
                            this.updateOverlapData({ values })
                          }}
                          
                          renderTrack={({ props, children }) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: '6px',
                                width: '100%',
                                backgroundColor: '#ccc'
                              }}
                            >
                              {children}
                            </div>
                          )}
                          renderThumb={({ props }) => (
                            <div
                            className='d-flex align-items-center justify-content-center'
                              {...props}
                              style={{
                                ...props.style,
                                height: '35px',
                                width: '35px',
                                backgroundColor: '#00A7D0',
                              }}
                            >
                              <span className="text-white bold h5 m-0 p-0">{this.state.values}</span>
                            </div>
                          )}
                        />
                        </div>
                            }
                        {this.state.overlapData &&
                        <div className="light-box-shadow-x overlap-table w-100 fade-up fade-2">
                            <DataTable
                            pagination
                            columns={overlapResultColumns}
                            data={this.state.overlapData}
                        />
                      </div>
                      }
                      {this.state.errorMessage &&
                        <span className="error small mt-2">{this.state.errorMessage}</span>
                      }
                        {!this.state.overlapData &&
                        <button onClick={this.getOverlapResultsNew} className="btn bg-brand-blue text-white font-weight-bold position-relative my-3 fade-up fade-3 py-2">
                          {!this.state.loadingOverlap &&
                            <span className="">Get Overlap Results</span>
                          }
                          {this.state.loadingOverlap &&
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden"></span>
                            </div>
                          }
                        </button>
                        }
                    
                        {this.state.overlapDownloadLink &&
                        <div className="d-flex w-100">
                          {this.state.filterChanged &&
                            <button onClick={this.saveOverlapResultsByCount} className="btn btn-text font-weight-bold position-relative my-4 fade-up fade-3 py-2 mr-4 light-box-shadow-x d-none">
                            {!this.state.loadingResultsByCount &&
                              <span className="">Get Filtered Results</span>
                            }
                            {this.state.loadingResultsByCount && 
                              <div className="spinner-border" role="status">
                                <span className="visually-hidden"></span>
                              </div>
                            }
                          </button>
                            }
       
                         <div className="d-flex mt-4 w-100 justify-content-between">
                         {!this.state.loadingResultsByCount && 
                            <div className="d-flex flex-column w-50 pr-3">

                            <a href={this.state.overlapDownloadLink} target="_blank" download className="btn bg-brand-blue text-white font-weight-bold position-relative fade-up fade-3 py-2 ml-auto light-box-shadow-x w-100">Download Raw CSV <img className="ml-3" src={downloadIcon} /></a>
                            <label className='d-flex flex-row align-items-center fade-up fade-2 pt-3 font-weight-bold'>
                                <input
                                  type="checkbox"
                                  className='checkbox mr-2'
                                  checked={this.state.checkedPublishFilteredData}
                                  onChange={this.handlePublishFilteredData}
                                />
                                Publish list to Haystack
                              </label>
                              {this.state.checkedPublishFilteredData && !this.state.checkedCustomPublishFilteredData &&
                              <div className='d-flex flex-column fade-up fade-1'>

                               <label className="d-flex flex-column font-weight-bold publish-form w-100">
                               Enter file name
                                   <input 
                                       type="text" 
                                       name="projectCode"
                                       placeholder={this.state.filenamePlaceholder}
                                       onChange={this.handleFilenameChange}
                                       value={this.state.publishFilteredDataFilename} 
                                       className="mt-2 light-box-shadow-x" />
                                       </label>
                              
                                {this.state.publishFilteredDataFilename &&
                                <button onClick={this.publishAllResults} className="btn bg-brand-blue text-white font-weight-bold position-relative fade-up fade-3 py-2 mt-4 light-box-shadow-x w-100">
                                     {!this.state.loadingPublishFile &&
                                        <span className="">Publish</span>
                                      }
                                      {this.state.loadingPublishFile && 
                                        <div className="spinner-border" role="status">
                                          <span className="visually-hidden"></span>
                                        </div>
                                      }
                                  </button>
                                }
                                  {this.state.publishMessage &&
                                    <div className='d-flex flex-column mt-4'>

                                    <span className="success small">{this.state.publishMessage}</span>
                                    <span className='font-weight-bold py-2'>File URL:</span>
                                    <div onClick={() => {navigator.clipboard.writeText(this.state.publishedFileUrl)}} 
                                          className="file-url d-flex light-box-shadow-x w-100 p-4 bg-white" >
                                      <span title={this.state.publishedFileUrl}>lists.haystackaudiences/...</span>
                                      <img className="ml-3" src={copyIcon} />
                                    </div>
                                    </div>
                                  }

                                </div>
                              }
                            </div>
                          
                          }
                         {parseInt(this.state.overlapCount) > 0 && parseInt(this.state.overlapCount) <= 25000 && 
                          <div className="d-flex flex-column w-50 pl-3">
                          <button onClick={this.getTwitterOverlapData} className="btn btn-text font-weight-bold position-relative fade-up fade-3 py-2 light-box-shadow-x w-100">
                            {!this.state.loadingOverlapDataTwitter &&
                              <span className="">Filter & Add Follower Data</span>
                            }
                            {this.state.loadingOverlapDataTwitter && 
                              <div className="spinner-border" role="status">
                                <span className="visually-hidden"></span>
                              </div>
                            }
                          </button>
                          {this.state.twitterOutputData &&
                          <div className='d-flex flex-column'>
                            <CSVLink 
                            data={this.state.twitterOutputData}
                            filename={this.state.jobDetails.projectcode + '-' + this.state.jobDetails.jobcode + '-' + this.state.jobDetails.cycle + "-follower-data.csv"}
                            className="btn bg-brand-blue text-white font-weight-bold d-flex align-items-center px-4 mt-4 light-box-shadow-x py-2 fade-up w-100">Download Filtered Data <img className="ml-3" src={downloadIcon} />
                            </CSVLink>
                            <label className='d-flex flex-row align-items-center fade-up fade-2 pt-3 font-weight-bold'>
                                <input
                                  type="checkbox"
                                  className='checkbox mr-2'
                                  checked={this.state.checkedCustomPublishFilteredData}
                                  onChange={this.handleCustomPublishFilteredData}
                                />
                                Publish list to Haystack
                              </label>
                              {this.state.checkedCustomPublishFilteredData && !this.state.checkedPublishFilteredData &&
                              <div className='d-flex flex-column fade-up fade-1'>

                               <label className="d-flex flex-column font-weight-bold publish-form w-100">
                               Enter file name
                                   <input 
                                       type="text" 
                                       name="projectCode"
                                       placeholder={this.state.filenamePlaceholder}
                                       onChange={this.handleFilenameChange}
                                       defaultValue={this.state.publishFilteredDataFilename} 
                                       className="mt-2 light-box-shadow-x" />
                                       </label>
                              
                                {this.state.publishFilteredDataFilename &&
                                <button onClick={this.publishResults} className="btn bg-brand-blue text-white font-weight-bold position-relative fade-up fade-3 py-2 mt-4 light-box-shadow-x w-100">
                                     {!this.state.loadingCustomPublishFile &&
                                        <span className="">Publish</span>
                                      }
                                      {this.state.loadingCustomPublishFile && 
                                        <div className="spinner-border" role="status">
                                          <span className="visually-hidden"></span>
                                        </div>
                                      }
                                  </button>
                                }
                                  {this.state.customPublishMessage &&
                                    <div className='d-flex flex-column mt-4'>

                                    <span className="success small">{this.state.customPublishMessage}</span>
                                    <span className='font-weight-bold py-2'>File URL:</span>
                                    <div onClick={() => {navigator.clipboard.writeText(this.state.publishedFileUrl)}} 
                                          className="file-url d-flex light-box-shadow-x w-100 p-4 bg-white" >
                                      <span title={this.state.publishedFileUrl}>lists.haystackaudiences/...</span>
                                      <img className="ml-3" src={copyIcon} />
                                    </div>
                                    </div>
                                  }

                                </div>
                              }

                            </div>
                          }
                          </div>
                          }
               
                      
                        
                        </div>
                
                          </div>
                        }      
                       
                    </div>
                  </div>
                  {this.state.overlapData &&
                  <div className="col-12 col-md-6 d-flex flex-wrap pr-0">

                    
                    <div className="col-12 col-md-6 fade-up fade-3">
                      <div className="card bg-white px-4 py-3 light-box-shadow-x">
                          <h5 className="font-weight-bold pt-2">Relationships</h5>
                          <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.overlapRelationships.toLocaleString()}</h5>
                        </div>
                    </div>

                     <div className="col-12 col-md-6 fade-up fade-4">
                      <div className="card bg-white px-4 py-3 light-box-shadow-x">
                          <h5 className="font-weight-bold pt-2">2+ Relationships</h5>
                          <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.overlapRelationships2.toLocaleString()}</h5>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 fade-up fade-5">
                      <div className="card bg-white px-4 py-3 light-box-shadow-x">
                          <h5 className="font-weight-bold pt-2">Unique Followers</h5>
                          <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.overlapCount.toLocaleString()}</h5>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 fade-up fade-6">
                      <div className="card bg-white px-4 py-3 light-box-shadow-x">
                          <h5 className="font-weight-bold pt-2">2+ Unique Followers</h5>
                          <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.overlapUnique2.toLocaleString()}</h5>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 fade-up fade-7">
                      <div className="card bg-white px-4 py-3 light-box-shadow-x">
                          <h5 className="font-weight-bold pt-2">Density</h5>
                          <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.overlapDensity.toLocaleString()}</h5>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 fade-up fade-8">
                      <div className="card bg-white px-4 py-3 light-box-shadow-x">
                          <h5 className="font-weight-bold pt-2">2+ Density</h5>
                          <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.overlapDensity2.toLocaleString()}</h5>
                        </div>
                    </div>

                  </div>
                  }
                  <div className="d-flex flex-wrap w-100 mt-4">
                    <div className="col-12 border-top-large"></div>
                    <div className="col-12 fade-up fade-3">
                    <h2 className="font-weight-bold mb-4 mt-0 text-uppercase">Job Details</h2>
                      </div>
                <div className="col-12 col-md-3 fade-up fade-4">
                <div className="card bg-white px-4 py-3 light-box-shadow-x">
                    <h4 className="font-weight-bold pt-2">Status</h4>
                    {this.state.jobStatus !== "Running" &&
                    <h5 className={`text-brand-blue font-weight-bold pb-2 status-${this.state.jobStatus}`}>{this.state.jobStatus}</h5>
                    }
                    {this.state.jobStatus === "Running" && 
                      <div>
                        <h5 className={`text-brand-blue font-weight-bold pb-2 status-${this.state.jobStatus}`}>{this.state.jobStatus}<span className="ml-1">({parseInt((parseInt(this.state.totalLines) / parseInt(this.state.followers)) * 100) }%)</span></h5>
                      </div>
                    }
                  </div>
                </div>
                <div className="col-12 col-md-3 fade-up fade-5">
                  <div className="card bg-white px-4 py-3 light-box-shadow-x">
                    {this.state.jobStatus === "Running" && 
                      <h4 className="font-weight-bold pt-2">Est. Time Left</h4>
                    }
                    {this.state.jobStatus === "Pending" &&
                    <h4 className="font-weight-bold pt-2">Est. Run Time</h4>
                    }
                    {this.state.jobStatus === "Done" &&
                    <h4 className="font-weight-bold pt-2">Run Time</h4>
                    }
                    <h5 className="text-brand-blue font-weight-bold pb-2">
                    {this.state.daysLeft > 0 &&
                      <span>
                        {this.state.daysLeft} days&nbsp;
                      </span>
                    }
                    {this.state.hoursLeft > 0 &&
                      <span>
                        {this.state.hoursLeft} hours
                      </span>
                    }
                    {this.state.minutesLeft > 0 &&
                      <span>
                        &nbsp;{this.state.minutesLeft} minutes
                      </span>
                    }
                    </h5> 
                 </div>
                </div>
                <div className="col-12 col-md-3 fade-up fade-6">
                <div className="card bg-white px-4 py-3 light-box-shadow-x">
                {this.state.endTime != null && this.state.jobDetails.status === "Running" &&
                  <div>
                    <h4 className="font-weight-bold pt-2">Est. End Time</h4>
                    <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.endTime} PST</h5>
                    </div>
                  }
                  {this.state.jobDetails.enddate != null && this.state.jobDetails.status === "Done" &&
                  <div>
                    <h4 className="font-weight-bold pt-2">Completed</h4>
                    <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.jobDetails.enddate} PST</h5>
                    </div>
                  }
                  {this.state.jobDetails.enddate == null && this.state.jobDetails.status === "Pending" &&
                  <div>
                    <h4 className="font-weight-bold pt-2">Est. End Time</h4>
                    <h5 className="text-brand-blue font-weight-bold pb-2">N/A</h5>
                    </div>
                  }
                  </div>
                </div>
                <div className="col-12 col-md-3 fade-up fade-7">
                  <div className="card bg-white px-4 py-3 light-box-shadow-x">
                      <h4 className="font-weight-bold pt-2">Followers</h4>
                      {this.state.followers &&
                      <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.followers.toLocaleString()}</h5>
                      }
                    </div>
                </div>


                  <div className="col-12 col-md-3 fade-up fade-8">
                      <div className="card bg-white px-4 py-3 light-box-shadow-x">
                        <h4 className="font-weight-bold pt-2">Cycle</h4>
                        <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.jobDetails.cycle}</h5>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 fade-up fade-9">
                      <div className="card bg-white px-4 py-3 light-box-shadow-x">
                        <h4 className="font-weight-bold pt-2">Accounts Found</h4>
                        {this.state.twitterAccounts &&
                        <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.twitterAccounts.length}</h5>
                        }
                      </div>
                    </div>
                    <div className="col-12 col-md-3 fade-up fade-10">
                    <div className="card bg-white px-4 py-3 light-box-shadow-x">
                        <h4 className="font-weight-bold pt-2">Errors</h4>
                        {this.state.twitterErrors &&
                          <h5 className="text-danger font-weight-bold pb-2">{this.state.twitterErrors.length}</h5>
                        }
                        {!this.state.twitterErrors &&
                          <h5 className="text-brand-blue font-weight-bold pb-2">0</h5>
                        } 
                      </div>
                    </div>
                    <div className="col-12 col-md-3 fade-up fade-11">
                    <div className="card bg-white px-4 py-3 light-box-shadow-x">
                        <h4 className="font-weight-bold pt-2">Owner</h4>
                        <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.jobDetails.submittedby}</h5>
                      </div>
                    </div>
                         

                  </div>
                </div>
              </div>

                <div className="row mt-4">
                  <div className="col-12">
                    {this.state.followers &&

                        <div className="file-records d-flex flex-column">
                       
                          <div className="row">
                                <div className="col-12 col-md-8">
                                {this.state.twitterErrors.length > 0 &&
                                  <div className="file-records d-flex flex-column mt-4 fade-up fade-10">
                                      <h3 className="font-weight-bold text-upperpcase pb-4">Errors</h3>
                                      <div className="light-box-shadow-x twitter-table">
                                          <DataTable
                                          columns={twitterAccountErrors}
                                          data={this.state.twitterErrors}
                                          pagination
                                      />
                                      </div>
                                  </div>
                                  }
                                <div className="file-records d-flex flex-column mt-4 fade-up fade-11">
                                    <div className="d-flex align-items-start pb-4">
                                    <h3 className="font-weight-bold">Twitter Accounts
                                    {this.state.jobStatus === "Running" &&
                                      <span className="h6 ml-2">{this.state.totalLines.toLocaleString()} / {this.state.followers.toLocaleString()}</span>
                                    }
                                    </h3>
                                    <div className="d-flex ml-auto guide align-items-start">
                                      {this.state.jobStatus === "Running" &&
                                        <div className="d-flex align-items-center">
                                            <span className="box box-current mr-2"></span>
                                            <span> Current Account</span>
                                          </div>
                                          }
                                          <div className="d-flex flex-column ml-3">
                                          <div className="d-flex align-items-center">
                                            <span className="box box-medium mr-2"></span>
                                            <span>&gt; 100,000 Followers</span>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <span className="box box-large mr-2"></span>
                                            <span>&gt; 1,00,000 Followers</span>
                                          </div>
                                            </div>
                                      </div>
                                    </div>
                                    <div className="light-box-shadow-x twitter-table">
                                          <DataTable
                                          pagination
                                          columns={twitterAccountColumns}
                                          data={this.state.twitterAccounts}
                                          paginationComponentOptions={paginationComponentOptions} 
                                          conditionalRowStyles={conditionalRowStyles}
                                      />
                                    </div>
                                </div>
                                </div>
                                <div className="col-12 col-md-4">
                                <div className="file-records d-flex flex-column fade-up fade-12 mt-4">
                                    <h3 className="font-weight-bold text-upperpcase pb-4">Cycles</h3>
                                    <div className="light-box-shadow-x fade-up fade-11">
                                      <DataTable
                                          columns={columns}
                                          data={this.state.relatedJobs}
                                          onRowClicked={this.viewJob}
                                          />
                                      </div>
                                </div>
                                </div>
                          </div>
                        </div>
                      }
                    </div> 
              </div>
              </div>
          }  
      </div>

    return (
        <div className="jobs-container">
            {contents}
        </div>
    );
  }    
}



