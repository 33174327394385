import React, { Component } from 'react';
import FileUpload from '../components/file-upload';
import JobQueuePreview from '../components/job-queue-preview';
import DataTable from 'react-data-table-component';
import Loader from '../components/loader';
import { config } from '../Config';
import templateFile from '../assets/files/template.csv';
import infoIcon from '../assets/images/info.png';
import ReactTooltip from 'react-tooltip';
const apiUrl = config.url.API_URL;
const apiKey = config.url.API_KEY;

export class Estimate extends Component {
    static displayName = Estimate.name;

    constructor(props) {
      super(props);
      this.state = {
        file: null,
        followers: '',
        runTime: '',
        records: 0,
        followerCount: '',
        jobData: 0,
        pendingJobs: 0,
        runningJobs: 0,
        loading: false
      };
    }

    componentDidMount() {
      this.getJobs();
    }
  
      getJobs() {
        fetch(apiUrl + '/tasks/all?key=' + apiKey)
          .then(res => res.json())
          .then((data) => {

              data.tasks.sort(function(a,b){
                return new Date(b.startdate) - new Date(a.startdate);
              });
  
              data = this.formatData(data.tasks);
  
              const pendingJobs = data.filter(obj => {
                return obj.status === 'Pending';
              });
  
              const runningJobs = data.filter(obj => {
                return obj.status === 'Running';
              });
              this.setState({
                jobData: data.tasks,
                runningJobs: runningJobs,
                pendingJobs: pendingJobs,
                loading: false
              });
              
          })
          .catch((error) => {
            this.setState({
              loading: false
            });
          });
      }
  
      formatData(items) {
  
        items.forEach((item) => {
  
            // // Event Date Day ( 2 Digit )
            if (item["startdate"]) {
                item["startdate"] = new Intl.DateTimeFormat('en-US', {month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(item["startdate"]));
            }
  
            // Create Job Name 
            if(item["projectcode"]) {
              item["projectcode"] = item["projectcode"] + " " + item["jobcode"];
            }
  
            // Calculate and format est run time 
            if(item["runtime"]){
              
              item["runtime"] = this.calculateJobTime(item);
            }
  
        });
  
        return items;
      }

    calculateJobTime(job){

      let minutes = job["runtime"];
      let minutesLeft = 0;
      let hoursLeft = 0;
      let daysLeft = 0;
      let estRunTime = "";

      if(minutes > 1440){
        let time = minutes / 60;
        let days = Math.floor(time/24);
        let remainder = time % 24;
        let hours = Math.floor(remainder);
        let hoursRemainder = hours % 60;
        let tempMinutes = Math.floor(hoursRemainder);
        daysLeft = days;
        hoursLeft = hours;
        minutesLeft = tempMinutes;
        estRunTime = daysLeft + "d " + hoursLeft + "h " + minutesLeft + "m";
      } 
      if (minutes > 60 && minutes <= 1440) {
        let hours = Math.floor(minutes / 60);
        let remainder = minutes % 60;
        let tempMinutes = Math.floor(remainder);
        daysLeft = 0;
        hoursLeft = hours;
        minutesLeft = tempMinutes;
        estRunTime = hoursLeft + "h " + minutesLeft + "m";
      }
      if (minutes < 60)  {
        daysLeft = 0;
        hoursLeft = 0;
        minutesLeft = minutes;
        estRunTime = minutesLeft + "m";
      }

      return estRunTime;

    }


  viewJob = (job) => {
    this.props.history.push(`/job/` + job.id);
  }
    

    pushFile = (file, followers, runTime, records, followerCount) => {
      this.setState({
          file: file,
          followers: followers,
          runTime: runTime,
          records: records,
          followerCount: followerCount
      });
    }

  render () {

    const columns = [
      {
          name: 'Job',
          selector: row => row.projectcode,
          sortable: true,
          tooltip: true
          // cell:(row) => row.projectcode + " " +  row.jobcode,
      },
      {
          name: 'Owner',
          selector: row => row.submittedby,
          sortable: true
      },
      {
        name: '~ Run Time',
        selector: row => row.runtime,
        sortable: true
      },
  ];

    const fileInputColumns = [
      {
          name: 'Follower ID',
          selector: row => row.Followerid,
          sortable: true
      },
      {
          name: 'Account ID',
          selector: row => row['account id'],
          sortable: true
      },
      {
        name: 'Twitter Handle',
        selector: row => row['Twitter Handle'],
        sortable: true
      },
      {
        name: 'Client',
        selector: row => row['Client'],
        sortable: true
      },
      {
        name: 'Project',
        selector: row => row['Project'],
        sortable: true
      },
      {
        name: 'Platform',
        selector: row => row['Platform'],
        sortable: true
      },
      {
        name: 'Handle_Type',
        selector: row => row['Handle_Type'],
        sortable: true
      },
      {
        name: 'Date_Pulled',
        selector: row => row['Client'],
        sortable: true
      },
      {
        name: 'Inf_Type',
        selector: row => row['Inf_Type'],
        sortable: true
      },
      {
        name: 'Inf_Lane',
        selector: row => row['Inf_Lane'],
        sortable: true
      },
  ];

  const twitterAccountErrors = [
    {
      name: 'ID',
      selector: row => row.value,
      sortable: true
  },
  {
      name: 'Error',
      selector: row => row['title'],
      sortable: true
  },
  ]

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Show All',
  };

  const conditionalRowStyles = [
    {
      when: row => row['public_metrics']['followers_count'] > 100000,
      style: {
        backgroundColor: 'rgba(255, 193, 7, .5)',
        color: 'black',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    {
      when: row => row['public_metrics']['followers_count'] > 1000000,
      style: {
        backgroundColor: 'rgba(220, 53, 69, .5)',
        color: 'black',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];

  const twitterAccountColumns  = [
    {
        name: 'ID',
        selector: row => row.id,
        sortable: true
    },
    {
        name: 'Username',
        selector: row => row['username'],
        sortable: true
    },
    {
      name: 'Name',
      selector: row => row['name'],
      sortable: true
    },
    {
      name: 'Followers',
      selector: row => row['public_metrics']['followers_count'],
      sortable: true
    },
    {
      name: 'Tweets',
      selector: row => row['public_metrics']['tweet_count'],
      sortable: true
    }
  ];

  let contents = this.state.loading
  ? 
  <div className="loader-container d-flex flex-column h-100 p-4 text-center justify-content-center align-items-center">
    <Loader />
  </div>
  :
  <div className="row">
  <div className="col-12 col-md-10 col-lg-8">
    <h4 className="text-uppercase font-weight-bold fade-up d-flex">
    Job Estimate
      <span className="d-flex align-self-center ml-2 p-1" data-tip data-for='info'><img className="info-icon" src={infoIcon} /></span>
    </h4>
    <ReactTooltip id='info' type='light' effect="solid" className="info-tooltip light-box-shadow-x" place="bottom" clickable={true}>
    <span className="h5 h-border text-uppercase font-weight-bold">Requirements<br /></span>
    <ul className="mt-2 pl-3 mb-3">
      <li>The headers <b>match</b> the template
        <ul className="my-2 pl-3 mb-0">
          <li>Followerid</li>
          <li>account id</li>
          <li>Twitter Handle</li>
          <li>Client</li>
          <li>Project</li>
          <li>Platform</li>
          <li>Handle_Type</li>
          <li>Date_Pulled</li>
          <li>Inf_Type</li>
          <li>Inf_Lane</li>
        </ul>
      </li>
      <li>Data is in the <b>correct</b> format</li>
      <li>Minimum of <b>one (1)</b> valid record</li>
    </ul>
    <span className="h5 h-border text-uppercase font-weight-bold">Limits: <br /></span>
    <ul className="mt-2 pl-3 mb-0">
      <li>260,0000 records / hour</li>
    </ul>
    <a href={templateFile} download className="btn bg-brand-blue w-100 text-white font-weight-bold position-relative mt-4 mb-0">Download Template File</a>
  </ReactTooltip>
      <p className="fade-up fade-2">Upload a .csv file to find the total number of followers and get an estimated completion time for the data pull via Haystack.</p>
  </div>
  <div className="col-12 col-md-10 col-lg-8">
      <div className="fade-up fade-3 bg-white p-4 light-box-shadow-x">
        <FileUpload pushFile = {this.pushFile} uploadFunction = {'estimate'}  />
      </div>
    </div>
    <div className="col-12 col-lg-4 mt-4 pt-4 pt-lg-0 mt-lg-0">
      <h4 className="text-uppercase font-weight-bold fade-up fade-4">Queue</h4>
      <div className="row">
        {this.state.runningJobs.length > 0 &&
          <div className="col-12 mb-4">
              <h5 className="text-uppercase font-weight-bold fade-up fade-4">Running ({this.state.runningJobs.length})</h5>
              <div className="light-box-shadow-x mt-4 fade-up fade-5">
                  <DataTable
                  columns={columns}
                  data={this.state.runningJobs}
                  onRowClicked={this.viewJob}
                  />
              </div>
          </div>
          }  
        {this.state.pendingJobs.length > 0 && 
          <div className="col-12 mb-4 pb-4">
              <h5 className="text-uppercase font-weight-bold fade-up fade-4">Pending ({this.state.pendingJobs.length})</h5>
              <div className="light-box-shadow-x mt-4 fade-up fade-5">
              <DataTable
                  columns={columns}
                  data={this.state.pendingJobs}
                  onRowClicked={this.viewJob}
                  />
              </div>
          </div>
        }
        {!this.state.pendingJobs.length > 0 && !this.state.runningJobs.length > 0 &&
          <div className="col-12">
            <h5 className="fade-up fade-4 text-center font-italic py-4">There are no jobs to show.</h5>
          </div>
          }
      </div>
    </div>
    <div className="col-12">
    {this.state.followers &&

        <div className="file-records d-flex flex-column">
          <div className="file-records-stats row pb-4 mb-4 mt-0 mt-md-4">
            <div className="col-12 pb-2 mb-4 d-flex align-items-center fade-up fade-8">
              <h2 className="font-weight-bold mb-0">Estimate Details</h2>
            </div>
            <div className="col-12 col-md-4 fade-up fade-9">
              <div className="card bg-white px-4 py-3 light-box-shadow-x">
                <h4 className="font-weight-bold pt-2">Accounts Found</h4>
                <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.followers.data.length}</h5>
              </div>
            </div>
            <div className="col-12 col-md-4 fade-up fade-10">
            <div className="card bg-white px-4 py-3 light-box-shadow-x">
                <h4 className="font-weight-bold pt-2">Errors</h4>
                {this.state.followers.errors &&
                  <h5 className="text-danger font-weight-bold pb-2">{this.state.followers.errors.length}</h5>
                }
                {!this.state.followers.errors &&
                  <h5 className="text-danger font-weight-bold pb-2">0</h5>
                } 
              </div>
            </div>
            <div className="col-12 col-md-4 fade-up fade-11">
            <div className="card bg-white px-4 py-3 light-box-shadow-x">
                <h4 className="font-weight-bold pt-2">Followers</h4>
                <h5 className="text-brand-blue font-weight-bold pb-2">{this.state.followerCount.toLocaleString()}</h5>
              </div>
            </div>
          </div>
          <div className="d-flex">
          <div className="file-records d-flex flex-column col-9 pl-0">
              <div className="d-flex align-items-start pb-4">
              <h3 className="font-weight-bold text-upperpcase">Accounts</h3>
                <div className="d-flex ml-auto guide flex-column">
                    <div className="d-flex align-items-center">
                      <span className="box box-medium mr-2"></span>
                      <span>&gt; 100,000 Followers</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="box box-large mr-2"></span>
                      <span>&gt; 1,00,000 Followers</span>
                    </div>
                </div>
              </div>
              <div className="light-box-shadow-x twitter-table">
                    <DataTable
                    columns={twitterAccountColumns}
                    data={this.state.followers.data}
                    pagination
                    conditionalRowStyles={conditionalRowStyles}
                    paginationComponentOptions={paginationComponentOptions} 
                />
              </div>
          </div>
          {this.state.followers.errors &&
          <div className="file-records d-flex flex-column col-3 pr-0">
              <h3 className="font-weight-bold text-upperpcase pb-4">Errors</h3>
              <div className="light-box-shadow-x twitter-table">
                  <DataTable
                  columns={twitterAccountErrors}
                  data={this.state.followers.errors}
              />
              </div>
          </div>
          }
          </div>
        </div>
      }
    </div>
  </div>

    return (
      <div className="container-fluid mt-4">
            {contents}
      </div>
    );
  }
}
